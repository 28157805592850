import React from 'react';

const ReadArticle = ({
  title, author, publishDate, readTime, image, content,
}) => {
  return (
    <div className="flex flex-col items-center pt-32 px-5">
      <h1 className="font-poppins text-3xl md:text-3xl
      font-bold text-[#333333] mb-6">
        {title}
      </h1>
      <p className="font-open-sans text-lg text-[#404040] mb-4">
                By {author} • {readTime} minute read • Published {publishDate}
      </p>
      <img src={image} alt={title} className="w-[800px]
      h-[450px] object-cover mb-8" />
      <div className="max-w-4xl w-full mb-6">
        {content.map((paragraph, index) => (
          <p key={index} className="font-open-sans text-lg text-[#404040] mb-5">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ReadArticle;
