import HeroSection from '../components/home_page/HeroSection';
import ServicesSection from '../components/home_page/ServicesSection';
import Process from '../components/home_page/Process';
import ContactSection from '../components/home_page/ContactSection';

const Home = () => {
  return (
    <main>
      <HeroSection />
      <ServicesSection />
      <Process />
      <ContactSection />
    </main>
  );
};

export default Home;
