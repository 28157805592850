import React from 'react';
import {Link} from 'react-router-dom';

const NewsArticleCard = ({title, image, description, link}) => {
  return (
    <div className="flex flex-col items-start rounded-lg
     mb-5 w-full bg-white">
      <img src={image} alt={title} className="w-[600px]
       h-[350px] object-cover mb-3 rounded-lg" />
      <h2 className="font-poppins text-xl font-bold text-[#333333] mb-2">
        {title}
      </h2>
      <p className="font-open-sans text-lg text-[#404040] mb-3">
        {description}
      </p>
      <Link
        to={link}
        className="bg-[#004080] text-white rounded-lg
        font-poppins text-lg font-bold py-2 px-4 hover:bg-[#003366]"
        onClick={() => window.scrollTo(0, 0)}
      >
                Read More
      </Link>
    </div>
  );
};

export default NewsArticleCard;
