import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import InquiryReceived from './pages/InquiryReceived';
import Services from './pages/Services';
import LearnMoreService from './pages/LearnMoreService';
import RequestMeeting from './pages/RequestMeeting';
import About from './pages/About';
import MeetingRequestReceived from './pages/MeetingRequestReceived';
import Careers from './pages/Careers';
import ApplicationReceived from './pages/ApplicationReceived';
// import Portfolio from './pages/Portfolio';
// import ViewProject from './pages/ViewProject';
import News from './pages/News';
import ReadArticle from './pages/ReadArticle';
import Contact from './pages/Contact';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import Tiago from './pages/Tiago';
import PortugalMilestone
  from './components/tiago_page/milestones/PortugalMilestone';
import NorwayMilestone
  from './components/tiago_page/milestones/NorwayMilestone';
import UniversityMilestone
  from './components/tiago_page/milestones/UniversityMilestone';
import CanadaMilestone
  from './components/tiago_page/milestones/CanadaMilestone';
import BritoConsultingMilestone
  from './components/tiago_page/milestones/BritoConsultingMilestone';
import CodeDaVinci from './components/tiago_page/projects/CodeDaVinci';
import OffshoreRigging from './components/tiago_page/projects/OffshoreRigging';
import LulAI from './components/tiago_page/projects/LulAI';
import ProtectedRoute from './ProtectedRoute';
import imageSoftwareService from './assets/services_page/image1.jpg';
import imageWebCreationService from './assets/services_page/image2.jpg';
import imageServiceMobile from './assets/services_page/mobileApp.png';
// PROJECTS
import project1 from './assets/portfolio_page/image1.jpg';
import project2 from './assets/portfolio_page/image2.webp';
import project3 from './assets/portfolio_page/image3.jpg';
import project4 from './assets/portfolio_page/image4.jpg';
import project5 from './assets/portfolio_page/image5.png';
import project6 from './assets/portfolio_page/image6.png';
import project7 from './assets/portfolio_page/image7.png';
import project8 from './assets/portfolio_page/image8.jpg';
import project9 from './assets/portfolio_page/image9.jpg';
import news1 from './assets/news_page/image1.webp';
import news2 from './assets/news_page/image2.png';
import news3 from './assets/news_page/image3.jpg';
// Font Awesome Icons
import {faBriefcase, faCogs, faChartLine, faShieldAlt, faMobile, faGlobe,
  faUser, faBolt, faLayerGroup}
  from '@fortawesome/free-solid-svg-icons';

const servicesData = [
  {
    title: 'Software Development',
    image: imageSoftwareService,
    description: 'Our custom software development services' +
      ' are designed to meet your business needs, ensuring' +
      ' seamless integration and scalability. From initial' +
      ' consultation to final deployment, our team of' +
      ' experts delivers high-quality software quickly and efficiently.',
    keyBenefits: [
      {description: 'Tailored to your specific business needs.',
        icon: faBriefcase},
      {description: 'Seamless integration with existing systems.',
        icon: faCogs},
      {description: 'Scalable solutions for future growth.',
        icon: faChartLine},
      {description: 'Enhanced security features.',
        icon: faShieldAlt},
    ],
    process: [
      {title: '', description:
        'Initial Consultation and Gathering', step: '1'},
      {title: '', description:
        'Detailed Project Planning', step: '2'},
      {title: '', description:
        'Design and Prototyping', step: '3'},
      {title: '', description:
        'Development and Testing', step: '4'},
      {title: '', description:
        'Deployment and Integration', step: '5'},
      {title: '', description:
        'Support and Maintenance', step: '6'},
    ],
    portfolio: [
      {title: 'Custom ERP System for Manufacturing',
        link: '/projects/custom-erp-system-for-manufacturing',
        image: project1,
        reverse: false,
        overview: 'This project involved developing ' +
        'a comprehensive ERP solution designed to ' +
        'streamline manufacturing processes and ' +
        'improve efficiency. Our team worked closely' +
        ' with the client to understand their ' +
        'specific needs and delivered a tailored ' +
        'system that integrates seamlessly with' +
        ' their existing operations.',
      },
      {title: 'CRM Integration for Retail',
        link: '/projects/crm-integration-for-retail',
        image: project2,
        reverse: true,
        overview: 'This project involved implementing a ' +
        'comprehensive CRM system to enhance customer ' +
        'relationship management and drive sales for a' +
        ' retail client. Our team collaborated closely ' +
        'with the client to customize the CRM to their ' +
        'specific needs, resulting in improved customer ' +
        'engagement and streamlined operations.',
      },
      {title: 'Inventory Management System for Healthcare',
        link: '/projects/inventory-management-system-for-healthcare',
        image: project3,
        reverse: false,
        overview: 'This project involved developing a ' +
        'robust inventory management system for a ' +
        'healthcare client to ensure efficient tracking ' +
        'and management of medical supplies. Our team' +
        ' collaborated closely with the client to ' +
        'understand their specific requirements and ' +
        'delivered a system that enhances operational' +
        ' efficiency and accuracy.',
      },
    ],
  },
  {
    title: 'Website Development',
    image: imageWebCreationService,
    description: 'Our website creation services are ' +
        'designed to deliver stunning, responsive websites' +
        ' that engage visitors and drive conversions. Whether' +
        ' you need an e-commerce platform, a corporate site,' +
        ' or a personal blog, we create websites that stand' +
        ' out and perform.',
    keyBenefits: [
      {description: 'Engaging and visually appealing designs.',
        icon: faGlobe},
      {description: 'Responsive and mobile-friendly layouts.',
        icon: faMobile},
      {description: 'Optimized for search engines (SEO).',
        icon: faBolt},
      {description: 'User-friendly navigation and interface.',
        icon: faUser},
    ],
    process: [
      {title: '', description:
        'Initial Consultation and Gathering', step: '1'},
      {title: '', description:
        'Design Mockups and Prototyping', step: '2'},
      {title: '', description:
        'Frontend, Backend Development', step: '3'},
      {title: '', description:
        'Quality Assurance and Testing', step: '4'},
      {title: '', description:
        'Deployment and Launch', step: '5'},
      {title: '', description:
        'Support and Maintenance', step: '6'},
    ],
    portfolio: [
      {title: 'E-commerce Platform for Fashion Retail',
        link: '/projects/e-commerce-platform-for-fashion-retail',
        image: project4,
        overview: 'This project involved creating a sleek and modern ' +
            'e-commerce platform tailored for a fashion retail client.' +
            ' Our team focused on delivering a visually appealing and' +
            ' user-friendly website that enhances the shopping ' +
            'experience for fashion enthusiasts.',
      },
      {title: 'Corporate Website for Finance',
        link: '/projects/corporate-website-for-finance',
        image: project5,
        reverse: true,
        overview: 'This project involved creating a professional ' +
            'corporate website designed to enhance the online ' +
            'presence of financial institutions. This website ' +
            'combines robust functionality with elegant design' +
            ' to provide clients with a seamless experience.',
      },
      {title: 'Personal Blog for Influencer',
        link: '/projects/personal-blog-for-influencer',
        image: project6,
        overview: 'This project involved creating a ' +
            'stylish and engaging personal blog ' +
            'platform for an influencer. The goal ' +
            'was to develop a website that allows ' +
            'the influencer to share content, connect' +
            ' with their audience, and showcase ' +
            'their personal brand effectively.',
      },
    ],
  },
  {
    title: 'Mobile App Development',
    image: imageServiceMobile,
    description: 'We build user-friendly mobile apps for' +
        ' iOS and Android that offer exceptional performance' +
        ' and intuitive interfaces. Our apps are designed to' +
        ' provide an outstanding user experience and meet ' +
        'your specific business goals.',
    keyBenefits: [
      {description: 'High performance and speed.',
        icon: faBolt},
      {description: 'Intuitive and user-friendly interfaces.',
        icon: faUser},
      {description: 'Cross-platform compatibility.',
        icon: faLayerGroup},
      {description: 'Regular updates and maintenance.',
        icon: faMobile},
    ],
    process: [
      {title: '', description:
        'Initial Consultation and Gathering', step: '1'},
      {title: '', description:
        'Detailed Project Planning', step: '2'},
      {title: '', description:
        'Design and Prototyping', step: '3'},
      {title: '', description:
        'Development and Testing', step: '4'},
      {title: '', description:
        'Deployment and Integration', step: '5'},
      {title: '', description:
        'Support and Maintenance', step: '6'},
    ],
    portfolio: [
      {title: 'Health and Fitness Tracking App',
        link: '/projects/health-and-fitness-tracking-app',
        image: project7,
        overview: 'This project involved creating a' +
            ' comprehensive e-learning app designed' +
            ' to provide an interactive and engaging ' +
            'learning experience for students. This' +
            ' app includes a variety of educational ' +
            'resources, interactive quizzes, and ' +
            'real-time feedback to enhance the learning process.',
      },
      {title: 'Social Media App for Communities',
        link: '/projects/social-media-app-for-communities',
        image: project8,
        reverse: true,
        overview: 'This project involved developing a' +
            ' social networking app designed to ' +
            'connect communities and foster communication ' +
            'and collaboration. The app aims to ' +
            'provide a platform where users can ' +
            'create profiles, join groups, ' +
            'share content, and engage in discussions' +
            ' with other community members.',
      },
      {title: 'E-learning App for Education',
        link: '/projects/e-learning-app-for-education',
        image: project9,
        overview: 'This project involved developing' +
            ' a comprehensive health and fitness ' +
            'tracking app designed to help users' +
            ' monitor their fitness goals,' +
            ' track workouts, and maintain a' +
            ' healthy lifestyle. The app ' +
            'provides various features to ' +
            'support users in their fitness' +
            ' journey, offering personalized' +
            ' recommendations and progress tracking.',
      },
    ],
  },
];

// const portfolioData = [
//   {
//     title: 'Custom ERP System for Manufacturing',
//     image: project1,
//     overview: 'This project involved developing ' +
//         'a comprehensive ERP solution designed to ' +
//         'streamline manufacturing processes and ' +
//         'improve efficiency. Our team worked closely' +
//         ' with the client to understand their ' +
//         'specific needs and delivered a tailored ' +
//         'system that integrates seamlessly with' +
//         ' their existing operations.',
//     details: [
//       'Frontend: React.js',
//       'Backend: Node.js, Express.js',
//       'Database: MongoDB',
//       'DevOps: Docker, Kubernetes, Jenkins',
//       'Hosting: AWS (Amazon Web Services)',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'Legacy System Integration: Integrating the new' +
//       ' ERP system with the client\'s existing legacy' +
//       ' systems without disrupting ongoing operations.',
//       'Scalability: Ensuring the system can scale ' +
//       'efficiently to accommodate future growth ' +
//       'and increased data volume.',
//       'User Adoption: Designing an intuitive user ' +
//       'interface to facilitate smooth user adoption' +
//       ' and minimize training requirements.',
//     ],
//     solutions: [
//       'API Development: Developed robust APIs to' +
//       ' facilitate seamless integration with the' +
//       ' legacy systems, ensuring data consistency' +
//       ' and real-time synchronization.',
//       'Modular Architecture: Implemented a modular' +
//       ' architecture to ensure the system\'s ' +
//       'scalability and flexibility, allowing for ' +
//       'easy addition of new features and modules.',
//       'User-Centric Design: Designed the UI/UX with' +
//       ' a focus on simplicity and usability, ' +
//       'conducting multiple user testing sessions' +
//       ' to refine the interface and improve user experience.',
//     ],
//     features: [
//       'Tailored to the client’s specific manufacturing processes.',
//       'Seamless integration with existing systems.',
//       'Scalable architecture for future growth.',
//       'Enhanced security features.',
//       'Real-time data tracking and reporting.',
//     ],
//   },
//   {
//     title: 'CRM Integration for Retail',
//     image: project2,
//     overview: 'This project involved implementing a ' +
//         'comprehensive CRM system to enhance customer ' +
//         'relationship management and drive sales for a' +
//         ' retail client. Our team collaborated closely ' +
//         'with the client to customize the CRM to their ' +
//         'specific needs, resulting in improved customer ' +
//         'engagement and streamlined operations.',
//     details: [
//       'Frontend: Angular',
//       'Backend: Node.js, Express.js',
//       'Database: PostgreSQL',
//       'CRM Platform: Salesforce',
//       'DevOps: Docker, Jenkins',
//       'Hosting: AWS (Amazon Web Services)',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'Data Migration: Migrating data from multiple' +
//       ' legacy systems to the new CRM platform ' +
//       'without data loss or corruption.',
//       'User Training: Ensuring all employees could' +
//       ' efficiently use the new CRM system with ' +
//       'minimal disruption to daily operations.',
//       'Integration: Integrating the CRM with existing' +
//       ' systems such as inventory management ' +
//       'and point-of-sale systems.',
//     ],
//     solutions: [
//       'Data Migration Plan: Developed a detailed ' +
//       'data migration plan that included data ' +
//       'cleaning, mapping, and validation to ensure' +
//       ' a smooth transition.',
//       'Training Programs: Conducted comprehensive ' +
//       'training programs and created user manuals' +
//       ' to facilitate quick user adoption and ' +
//       'minimize downtime.',
//       'Seamless Integration: Used robust APIs and' +
//       ' middleware to ensure seamless integration' +
//       ' between the CRM and other systems, allowing' +
//       ' for real-time data synchronization.',
//     ],
//     features: [
//       'Enhanced customer relationship management and engagement.',
//       'Real-time data synchronization with existing systems.',
//       'Comprehensive reporting and analytics.',
//       'Scalable architecture for future enhancements.',
//       'Intuitive user interface and user-friendly design.',
//     ],
//   },
//   {
//     title: 'Inventory Management System for Healthcare',
//     image: project3,
//     overview: 'This project involved developing a ' +
//         'robust inventory management system for a ' +
//         'healthcare client to ensure efficient tracking ' +
//         'and management of medical supplies. Our team' +
//         ' collaborated closely with the client to ' +
//         'understand their specific requirements and ' +
//         'delivered a system that enhances operational' +
//         ' efficiency and accuracy.',
//     details: [
//       'Frontend: Vue.js',
//       'Backend: Django, Django REST Framework',
//       'Database: PostgreSQL',
//       'DevOps: Docker, Kubernetes',
//       'Hosting: Google Cloud Platform (GCP)',
//       'Other Tools: Celery, Redis, Webpack, Babel',
//     ],
//     challenges: [
//       'Real-time Tracking: Implementing real-time ' +
//       'tracking of medical supplies to ensure ' +
//       'accuracy and prevent shortages.',
//       'Compliance: Ensuring the system complies' +
//       ' with healthcare regulations and standards.',
//       'Integration: Integrating the system with ' +
//       'existing electronic health records (EHR) ' +
//       'and hospital management systems.',
//     ],
//     solutions: [
//       'IoT Integration: Utilized IoT devices for ' +
//       'real-time tracking and monitoring of ' +
//       'inventory levels, ensuring timely updates and alerts.',
//       'Regulatory Compliance: Implemented features' +
//       ' that ensure compliance with healthcare ' +
//       'regulations, including secure data handling' +
//       ' and audit trails.',
//       'Seamless Integration: Developed APIs to ' +
//       'integrate seamlessly with existing EHR ' +
//       'and hospital management systems, allowing ' +
//       'for streamlined data flow and interoperability.',
//     ],
//     features: [
//       'Real-time tracking and monitoring of inventory levels.',
//       'Compliance with healthcare regulations and standards.',
//       'Seamless integration with existing EHR and hospital management',
//       'systems.',
//       'Automated restocking and order management.',
//       'Detailed reporting and analytics for better decision-making.',
//     ],
//   },
//   {
//     title: 'E-commerce Platform for Fashion Retail',
//     image: project4,
//     overview: 'This project involved creating a sleek and modern ' +
//         'e-commerce platform tailored for a fashion retail client.' +
//         ' Our team focused on delivering a visually appealing and' +
//         ' user-friendly website that enhances the shopping ' +
//         'experience for fashion enthusiasts.',
//     details: [
//       'Frontend: React.js',
//       'Backend: Node.js, Express.js',
//       'Database: MongoDB',
//       'Payment Integration: Stripe, PayPal',
//       'DevOps: Docker, Kubernetes',
//       'Hosting: AWS (Amazon Web Services)',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'High Traffic Management: Ensuring the platform' +
//       ' can handle high traffic during peak shopping seasons.',
//       'Security: Implementing secure payment processing' +
//       ' and protecting user data.',
//       'User Experience: Designing an intuitive and ' +
//       'visually appealing interface to enhance the ' +
//       'shopping experience.',
//     ],
//     solutions: [
//       'Scalable Infrastructure: Implemented a scalable' +
//       ' architecture using Docker and Kubernetes to' +
//       ' manage high traffic efficiently.',
//       'Enhanced Security: Integrated secure payment' +
//       ' gateways and implemented robust security' +
//       ' measures to protect user data.',
//       'UI/UX Design: Focused on a user-centric ' +
//       'design approach, conducting multiple usability' +
//       ' tests to ensure a seamless shopping experience.',
//     ],
//     features: [
//       'Sleek and modern user interface.',
//       'Secure payment processing with Stripe and PayPal integration.',
//       'Scalable infrastructure to handle high traffic.',
//       'Real-time inventory management.',
//       'Comprehensive product search and filtering options.',
//       'User reviews and ratings.',
//     ],
//   },
//   {
//     title: 'Corporate Website for Finance',
//     image: project5,
//     overview: 'This project involved creating a professional ' +
//         'corporate website designed to enhance the online ' +
//         'presence of financial institutions. This website ' +
//         'combines robust functionality with elegant design' +
//         ' to provide clients with a seamless experience.',
//     details: [
//       'Frontend: React.js',
//       'Backend: Node.js, Express.js',
//       'Database: MongoDB',
//       'Payment Integration: Stripe',
//       'DevOps: Docker, Kubernetes',
//       'Hosting: AWS (Amazon Web Services)',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'Security Compliance: Ensuring the website ' +
//       'meets stringent financial regulations and ' +
//       'data protection standards.',
//       'Scalability: Designing a system that can ' +
//       'handle a growing number of users and' +
//       ' transactions without performance degradation.',
//       'User Experience: Creating an intuitive and' +
//       ' engaging interface tailored for financial ' +
//       'professionals and clients.',
//     ],
//     solutions: [
//       'Advanced Security Measures: Implemented ' +
//       'robust security protocols, including encryption' +
//       ' and two-factor authentication, to protect ' +
//       'sensitive financial data.',
//       'Scalable Architecture: Designed a scalable ' +
//       'infrastructure using Docker and Kubernetes' +
//       ' to efficiently manage increasing user loads.',
//       'User-Centric Design: Focused on a ' +
//       'user-centered design approach, conducting ' +
//       'multiple usability tests to ensure a ' +
//       'seamless and engaging user experience.',
//     ],
//     features: [
//       'Elegant and professional design.',
//       'Advanced security features with encryption' +
//       ' and two-factor authentication.',
//       'Scalable architecture to handle growing user base.',
//       'Real-time data analytics and reporting.',
//       'Integration with financial APIs for seamless transactions.',
//     ],
//   },
//   {
//     title: 'Personal Blog for Influencer',
//     image: project6,
//     overview: 'This project involved creating a ' +
//         'stylish and engaging personal blog ' +
//         'platform for an influencer. The goal ' +
//         'was to develop a website that allows ' +
//         'the influencer to share content, connect' +
//         ' with their audience, and showcase ' +
//         'their personal brand effectively.',
//     details: [
//       'Frontend: React.js',
//       'Backend: Node.js, Express.js',
//       'Database: MongoDB',
//       'Content Management: Ghost CMS',
//       'DevOps: Docker, Jenkins',
//       'Hosting: DigitalOcean',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'Content Management: Providing an easy-to-use' +
//       ' content management system for the influencer' +
//       ' to manage blog posts, images, and other media.',
//       'SEO Optimization: Ensuring the blog is optimized' +
//       ' for search engines to increase visibility ' +
//       'and attract more readers.',
//       'User Engagement: Designing features to engage' +
//       ' readers and encourage interaction, such as ' +
//       'comments, likes, and social media sharing.',
//     ],
//     solutions: [
//       'Ghost CMS: Integrated Ghost CMS for its ' +
//       'user-friendly interface and powerful ' +
//       'content management features, allowing ' +
//       'the influencer to easily manage and ' +
//       'publish content.',
//       'SEO Techniques: Implemented SEO best' +
//       ' practices, including keyword optimization,' +
//       ' meta tags, and mobile-friendly design, ' +
//       'to enhance search engine performance.',
//       'Interactive Features: Added interactive ' +
//       'elements like a comments section, social' +
//       ' media sharing buttons, and a like system ' +
//       'to encourage reader engagement and interaction.',
//     ],
//     features: [
//       'User-friendly content management with Ghost CMS.',
//       'SEO optimized for better search engine visibility.',
//       'Responsive design for all devices.',
//       'Interactive features like comments, likes, ' +
//       'and social media sharing.',
//       'Stylish and engaging user interface.',
//       'Secure and scalable architecture.',
//     ],
//   },
//   {
//     title: 'E-learning App for Education',
//     image: project7,
//     overview: 'This project involved creating a' +
//         ' comprehensive e-learning app designed' +
//         ' to provide an interactive and engaging ' +
//         'learning experience for students. This' +
//         ' app includes a variety of educational ' +
//         'resources, interactive quizzes, and ' +
//         'real-time feedback to enhance the learning process.',
//     details: [
//       'Frontend: React Native',
//       'Backend: Node.js, Express.js',
//       'Database: MongoDB',
//       'Authentication: Firebase',
//       'DevOps: Docker, Kubernetes',
//       'Hosting: AWS (Amazon Web Services)',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'Scalability: Ensuring the app can handle' +
//       ' a large number of simultaneous users ' +
//       'without performance issues.',
//       'Engagement: Creating an engaging and ' +
//       'interactive user interface that keeps' +
//       ' students motivated and interested.',
//       'Real-time Feedback: Implementing a ' +
//       'system for providing real-time feedback' +
//       ' and analytics to both students and educators.',
//     ],
//     solutions: [
//       'Scalable Infrastructure: Designed a ' +
//       'scalable infrastructure using Docker' +
//       ' and Kubernetes to efficiently manage ' +
//       'high traffic and large numbers of simultaneous users.',
//       'Interactive UI/UX Design: Developed an' +
//       ' intuitive and engaging user interface ' +
//       'with interactive elements such as quizzes,' +
//       ' videos, and discussion forums.',
//       'Real-time Data Processing: Integrated ' +
//       'real-time data processing to provide ' +
//       'immediate feedback on quizzes and ' +
//       'assignments, and to generate analytics' +
//       ' for educators.',
//     ],
//     features: [
//       'Interactive quizzes and assignments.',
//       'Real-time feedback and analytics for students and educators.',
//       'A variety of educational resources, ' +
//       'including videos, articles, and interactive content.',
//       'User-friendly interface designed for ease of use and engagement.',
//       'Secure authentication and user management with Firebase.',
//       'Scalable infrastructure to handle large numbers of users.',
//     ],
//   },
//   {
//     title: 'Social Networking App for Communities',
//     image: project8,
//     reverse: true,
//     overview: 'This project involved developing a' +
//         ' social networking app designed to ' +
//         'connect communities and foster communication ' +
//         'and collaboration. The app aims to ' +
//         'provide a platform where users can ' +
//         'create profiles, join groups, ' +
//         'share content, and engage in discussions' +
//         ' with other community members.',
//     details: [
//       'Frontend: Flutter',
//       'Backend: Node.js, Express.js',
//       'Database: MongoDB',
//       'Authentication: Firebase',
//       'DevOps: Docker, Kubernetes',
//       'Hosting: Google Cloud Platform (GCP)',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'Scalability: Ensuring the app can handle' +
//       ' a large number of users and high ' +
//       'levels of interaction.',
//       'Security: Implementing secure ' +
//       'authentication and data protection measures.',
//       'User Engagement: Creating features that' +
//       ' encourage user engagement and interaction.',
//     ],
//     solutions: [
//       'Scalable Architecture: Designed the app ' +
//       'with a scalable architecture using ' +
//       'Docker and Kubernetes to manage high' +
//       ' traffic and user loads efficiently.',
//       'Secure Authentication: Integrated Firebase' +
//       ' for secure user authentication and ' +
//       'implemented data encryption for added security.',
//       'Engagement Features: Added features' +
//       ' such as group creation, real-time' +
//       ' chat, notifications, and ' +
//       'content sharing to enhance user engagement.',
//     ],
//     features: [
//       'User profiles and customizable settings.',
//       'Group creation and management.',
//       'Real-time chat and messaging.',
//       'Content sharing and media upload.',
//       'Push notifications for updates and interactions.',
//       'Secure authentication and data encryption.',
//     ],
//   },
//   {
//     title: 'Health and Fitness Tracking App',
//     image: project9,
//     overview: 'This project involved developing' +
//         ' a comprehensive health and fitness ' +
//         'tracking app designed to help users' +
//         ' monitor their fitness goals,' +
//         ' track workouts, and maintain a' +
//         ' healthy lifestyle. The app ' +
//         'provides various features to ' +
//         'support users in their fitness' +
//         ' journey, offering personalized' +
//         ' recommendations and progress tracking.',
//     details: [
//       'Frontend: Flutter',
//       'Backend: Node.js, Express.js',
//       'Database: Firebase',
//       'APIs: Google Fit, Apple HealthKit',
//       'DevOps: Docker, Kubernetes',
//       'Hosting: Google Cloud Platform (GCP)',
//       'Other Tools: Redux, Webpack, Babel',
//     ],
//     challenges: [
//       'Data Integration: Integrating with ' +
//       'various health APIs and ensuring ' +
//       'accurate data synchronization.',
//       'User Engagement: Designing features' +
//       ' that motivate users to maintain' +
//       ' consistent fitness routines.',
//       'Security: Protecting sensitive health' +
//       ' data with robust security measures.',
//     ],
//     solutions: [
//       'API Integration: Seamlessly integrated' +
//       ' with Google Fit and Apple HealthKit' +
//       ' APIs to provide users with comprehensive health data.',
//       'Gamification: Incorporated gamification' +
//       ' elements such as achievements, ' +
//       'challenges, and rewards to enhance user engagement.',
//       'Enhanced Security: Implemented ' +
//       'encryption and secure authentication ' +
//       'methods to protect user data.',
//     ],
//     features: [
//       'Comprehensive health and fitness tracking.',
//       'Integration with Google Fit and Apple HealthKit.',
//       'Personalized workout recommendations.',
//       'Gamification elements to boost engagement.',
//       'Real-time progress tracking and analytics.',
//       'Secure data encryption and authentication.',
//     ],
//   },
// ];

const articlesData = [
  {
    title: 'Brito Consulting Expands to New Office in Toronto',
    author: 'Michael Lee',
    publishDate: '9:00 AM EST, Tue July 11, 2024',
    readTime: 5,
    image: news1,
    content: [
      `Brito Consulting is thrilled to announce the opening of our new
      office in Toronto.`,
      `This expansion marks a significant milestone in our company's
      growth and our commitment to better serving our clients in the
      region.`,
      `The new office is located in the heart of Toronto’s financial
      district, providing us with a strategic advantage in accessing
      key clients and resources. The location was chosen after a
      thorough analysis of market opportunities and client needs.`,
      `Our team in Toronto will focus on delivering our core services,
      including custom software development, website creation, and
      mobile app development. With this expansion, we are better
      positioned to meet the growing demand for our services in North
      America.`,
      `We have invested in state-of-the-art facilities to ensure our
      team has the best tools and environment to deliver top-notch
      services. The new office is equipped with advanced technology and
      collaborative spaces designed to foster innovation and
      productivity.`,
      `"This new office allows us to better serve our clients in North
      America and continue our mission to deliver exceptional
      consulting services." -Tiago Brito, Founder & CEO.`,
      `We look forward to welcoming you to our new office and
      continuing to provide the high-quality consulting services you
      have come to expect from Brito Consulting.`,
    ],
  },
  {
    title: 'Top 5 Emerging Technologies in Software Development for 2024',
    author: 'Jane Doe',
    publishDate: '10:00 AM EST, Mon August 1, 2024',
    readTime: 7,
    image: news2,
    content: [
      `As we look ahead to 2024, several emerging technologies are set
      to transform the software development landscape. In this article,
      we explore the top five technologies that developers and
      businesses should keep an eye on.`,
      `1. Artificial Intelligence and Machine Learning: AI and ML
      continue to dominate the tech industry, offering unprecedented
      opportunities for automation, data analysis, and predictive
      modeling.`,
      `2. Quantum Computing: Quantum computers are expected to
      revolutionize various fields by solving complex problems that
      are currently beyond the capabilities of classical computers.`,
      `3. Edge Computing: With the rise of IoT devices, edge computing
      is becoming increasingly important. It allows data processing to
      occur closer to the data source, reducing latency and improving
      efficiency.`,
      `4. 5G Technology: The deployment of 5G networks promises faster
      internet speeds, lower latency, and the potential for new
      applications in augmented reality (AR) and virtual reality
      (VR).`,
      `5. Blockchain: Beyond cryptocurrencies, blockchain technology
      is being explored for applications in supply chain management,
      secure voting systems, and more.`,
      `These technologies represent just a glimpse of the innovations
      that are shaping the future of software development. Staying
      informed and adapting to these changes will be crucial for
      businesses and developers alike.`,
    ],
  },
  {
    title: 'Join Us at the 2024 Tech Innovators Conference',
    author: 'John Smith',
    publishDate: '2:00 PM EST, Fri September 15, 2024',
    readTime: 4,
    image: news3,
    content: [
      `Brito Consulting will be attending the 2024 Tech Innovators
      Conference.`,
      `Join us to explore the latest in technology innovation and
      network with industry leaders. Here's what you can expect from
      the event:`,
      `1. Keynote Speeches: Industry experts will share their insights
      on the latest trends and future directions in technology.`,
      `2. Panel Discussions: Engage in thought-provoking discussions
      with leaders from various tech sectors.`,
      `3. Networking Opportunities: Connect with professionals,
      entrepreneurs, and investors from around the world.`,
      `4. Workshops: Participate in hands-on workshops to gain
      practical skills and knowledge.`,
      `5. Exhibition Hall: Explore the latest products and services
      from leading tech companies.`,
      `We look forward to seeing you at the conference and discussing
      how we can collaborate to drive innovation in the tech industry.
      Don't miss this opportunity to stay ahead of the curve and be
      part of the conversation about the future of technology.`,
    ],
  },
];

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/inquiry-received" element={<InquiryReceived />} />
            <Route path="/services" element={<Services />} />
            {servicesData.map((service, index) => (
              <Route
                key={index}
                path={`/services/${service.title.
                    toLowerCase().split(' ').join('-')}`}
                element={
                  <LearnMoreService
                    title={service.title}
                    image={service.image}
                    description={service.description}
                    keyBenefits={service.keyBenefits}
                    process={service.process}
                    // portfolioData={service.portfolio}
                  />
                }
              />
            ))}
            {/* {portfolioData.map((project, index) => (
              <Route
                key={index}
                path={`/projects/${project.title.
                    toLowerCase().split(' ').join('-')}`}
                element={
                  <ViewProject
                    title={project.title}
                    image={project.image}
                    overview={project.overview}
                    details={project.details}
                    challenges={project.challenges}
                    solutions={project.solutions}
                    features={project.features}
                  />
                }
              />
            ))} */}
            <Route path="/request-meeting" element={<RequestMeeting />} />
            <Route path="/meeting-request-received"
              element={<MeetingRequestReceived />} />
            <Route path="/about" element={<About />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/application-received"
              element={<ApplicationReceived />} />
            {/* <Route path="/portfolio" element={<Portfolio />} /> */}
            <Route path="/news" element={<News />} />
            {articlesData.map((article, index) => (
              <Route
                key={index}
                path=
                  {`/news/${article.title.toLowerCase().split(' ').join('-')}`}
                element={
                  <ReadArticle
                    title={article.title}
                    author={article.author}
                    publishDate={article.publishDate}
                    readTime={article.readTime}
                    image={article.image}
                    content={article.content}
                  />
                }
              />
            ))}
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
              path="/admin/dashboard"
              element={<ProtectedRoute element={AdminDashboard} />}
            />
            <Route path="/tiago" element={<Tiago />}/>
            <Route path="/tiago/milestones/portugal"
              element={<PortugalMilestone />}/>
            <Route path="/tiago/milestones/norway"
              element={<NorwayMilestone />}/>
            <Route path="/tiago/milestones/university"
              element={<UniversityMilestone />}/>
            <Route path="/tiago/milestones/canada"
              element={<CanadaMilestone />}/>
            <Route path="/tiago/milestones/brito-consulting"
              element={<BritoConsultingMilestone />}/>
            <Route path="/tiago/projects/code-da-vinci"
              element={<CodeDaVinci />}/>
            <Route path="/tiago/projects/offshore-rigging"
              element={<OffshoreRigging />}/>
            <Route path="/tiago/projects/lulai"
              element={<LulAI />}/>
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
