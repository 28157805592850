import React from 'react';
import imageBritoConsulting
  from '../../../assets/tiago_page/toronto.jpg';

const BritoConsultingMilestone = () => {
  const paragraph1 = (
    <>
        Founding Brito Consulting marked the beginning
        of a pivotal chapter in my life. Before arriving
        in Toronto, I had explored various startup ideas,
        including Learnel, an education and entertainment
        platform aimed at making learning more engaging
        for students. However, after valuable feedback at
        Web Summit 2023 in Lisbon, I realized that to make
        a meaningful impact, I needed to start with
        something more focused and practical.
    </>
  );

  const paragraph2 = (
    <>
        This led to the creation of Brito Consulting,
        a company dedicated to providing software development,
        website development, and mobile app development
        tailored to the needs of businesses. Incorporating
        the company in Toronto was a bold decision,
        especially as I was still adjusting to a new country
        and city. However, I believed that this venture would
        lay a strong foundation for future innovations and
        help me gain the necessary experience to tackle more
        ambitious projects later on.
    </>
  );

  const paragraph3 = (
    <>
            Launching Brito Consulting came with its challenges.
            Balancing technical expertise with an understanding
            of business dynamics and client needs required a
            steep learning curve. Yet, these challenges also
            brought immense growth. Building a company from the
            ground up, managing a team, and delivering real value
            to clients has been a transformative experience,
            reinforcing my entrepreneurial spirit and shaping
            my approach to business.
    </>
  );

  const paragraph4 = (
    <>
            In retrospect, the founding of Brito Consulting
            represents the merging of my technical skills
            with my entrepreneurial vision. It’s the start
            of a journey that I hope will not only change
            my life but also positively impact the
            businesses I work with. This venture is just
            the beginning, and I am eager to see where it
            will lead, knowing that the lessons I’ve
            learned will guide me in future endeavors.
    </>
  );

  return (
    <section className="py-10 md:py-20 bg-gray-50 text-gray-800 px-8">
      <div className="container mx-auto">
        <img src={imageBritoConsulting} alt="Brito Consulting"
          className="w-full h-64 md:h-96 object-cover
                     rounded-lg mb-8"/>
        <h2 className="text-3xl md:text-4xl font-bold
                mb-6">Founding Brito Consulting</h2>
        <p className="text-lg md:text-xl
                leading-relaxed">{paragraph1}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph2}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph3}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph4}</p>
      </div>
    </section>
  );
};

export default BritoConsultingMilestone;
