import React from 'react';
import ServiceCard from './ServiceCard';

const ServicesSection = () => {
  const services = [
    {
      icon: (
        <img
          src="https://www.svgrepo.com/show/530444/availability.svg"
          alt="Software Development"
          className="mx-auto h-20 w-20"
        />
      ),
      title: 'Software Development',
      description:
      ' We provide custom software solutions tailored to the needs of ' +
          'your business,' +
      ' ensuring seamless integration and scalability. From initial' +
      ' consultation to final deployment, our team of experts delivers' +
      ' high-quality software quickly and efficiently.',
    },
    {
      icon: (
        <img
          src="https://www.svgrepo.com/show/530443/interface-control.svg"
          alt="Website Development"
          className="mx-auto h-20 w-20"
        />
      ),
      title: 'Website Development',
      description: 'Our team designs and develops stunning, responsive' +
      ' websites that engage visitors and drive conversions. Whether you' +
      ' need an e-commerce platform, a corporate site, or a personal' +
      ' blog, we create websites that stand out and perform.',
    },
    {
      icon: (
        <img
          src="https://www.svgrepo.com/show/530452/mobile-app.svg"
          alt="Mobile App Development"
          className="mx-auto h-20 w-20"
        />
      ),
      title: 'Mobile App Development',
      description: 'We build user-friendly mobile apps for iOS and' +
      ' Android that offer exceptional performance and intuitive interfaces.' +
      ' Our apps are designed to provide an outstanding user experience and' +
      ' meet your specific business goals.',
    },
  ];

  return (
    <section id="services-section"
      className="px-5 sm:px-10 md:px-32 lg:px-10
        py-28 text-center lg:py-40">
      <div id="features" className="mx-auto max-w-7xl text-primary-500">
        <p
          className="text-sm font-bold uppercase tracking-widest
          text-[#004080]">
          Our Services
        </p>
        <h2
          className="mt-6 text-3xl font-bold tracking-tight sm:text-4xl
          lg:text-5xl text-[#004080] drop-shadow-sm"
        >
          Transform Your Online Impact
        </h2>
        <p
          className="mx-auto mt-4 max-w-4xl text-md md:text-lg font-normal
          text-[#333333] lg:text-xl lg:leading-8 mb-20"
        >
          How we help companies enhance their digital
          presence and thrive using technology
        </p>
        <ul
          className="mt-10 grid grid-cols-1
        gap-6 text-center text-slate-700 lg:grid-cols-3"
        >
          {services.map((service, index) => (
            <li
              key={index}
            >
              <ServiceCard
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ServicesSection;
