import React from 'react';
import profileImage from '../../assets/tiago_page/start_hack.jpg';
import linkedinIcon from '../../assets/tiago_page/linkedin.svg';
import instagramIcon from '../../assets/tiago_page/instagram.svg';
import youtubeIcon from '../../assets/tiago_page/youtube.svg';

// Define the constant text
const introText = `Tiago Brito is an entrepreneur with a 
global perspective, blending his Portuguese and Brazilian heritage
 into a dynamic approach to business. His journey took him from 
 Portugal to Norway, where he embraced opportunities for growth 
 and innovation. Now based in Canada, Tiago is the Founder of 
 Brito Consulting, a business where he channels his passion for technology 
 and relentless pursuit of excellence into creating cutting-edge
  solutions for businesses across the world.`;

const PersonalIntroduction = () => {
  return (
    <section id="personal-intro" className="py-20 bg-white text-gray-800 px-8">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <img
          src={profileImage}
          alt="Tiago Brito"
          className="w-64 h-64 md:w-80 md:h-80 rounded-full
          shadow-lg mx-auto md:mx-0 mt-8"
        />
        <div className="text-center md:text-left mt-8 md:mt-0 px-8">
          <h2 className="text-3xl md:text-4xl font-bold">Tiago Brito</h2>
          <p className="mt-4 text-lg text-left">
            {introText}
          </p>
          {/* Social Media Links */}
          <div className="flex items-center gap-4 justify-center
           md:justify-start mt-6">
            <a
              href="https://www.linkedin.com/in/britotiago101/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 h-12 bg-gray-100 rounded-full flex
               items-center justify-center hover:bg-gray-200 transition"
            >
              <img src={linkedinIcon} alt="LinkedIn" className="w-6 h-6" />
            </a>
            <a
              href="https://www.instagram.com/britotiago._/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 h-12 bg-gray-100 rounded-full flex
               items-center justify-center hover:bg-gray-200 transition"
            >
              <img src={instagramIcon} alt="Instagram" className="w-6 h-6" />
            </a>
            <a
              href="https://www.youtube.com/@LearnWithBrito"
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 h-12 bg-gray-100 rounded-full flex
               items-center justify-center hover:bg-gray-200 transition"
            >
              <img src={youtubeIcon} alt="YouTube" className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalIntroduction;
