import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({username: '', password: ''});
  const [errors, setErrors] = useState({});
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleChange = (e) => {
    const {id, value} = e.target;
    setFormData((prevData) => ({...prevData, [id]: value}));
    setErrors((prevErrors) => ({...prevErrors, [id]: ''}));
  };

  const validateForm = () => {
    const newErrors = {};
    const errorRefs = [];

    if (!formData.username) {
      newErrors.username = 'Username is required';
      errorRefs.push(usernameRef);
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
      errorRefs.push(passwordRef);
    }

    setErrors(newErrors);

    if (errorRefs.length > 0) {
      scrollToRef(errorRefs[0]);
    }

    return Object.keys(newErrors).length === 0;
  };

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 110,
        behavior: 'smooth',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/users/login`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formData),
          });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        navigate('/admin/dashboard');
      } else {
        const errorData = await response.json();
        setErrors({form: errorData.message || 'Authentication failed.'});
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrors({
        form: error.message.includes('Failed to fetch') ?
            'The server is currently down. Please try again later.' :
            'Invalid username or password. Please try again.',
      });
    }
  };

  return (
    <div className="flex flex-col items-center pt-32 px-5 mb-8">
      <h1 className="font-poppins text-3xl font-bold text-[#333333] mb-8">
        Admin Login</h1>
      <form className="max-w-3xl w-full" onSubmit={handleSubmit} noValidate>
        <div className="mb-4">
          <label htmlFor="username" className="font-open-sans text-lg
          text-[#404040] block mb-2">
              Username
          </label>
          <input
            id="username"
            type="text"
            className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px] border
             border-[#CCCCCC] px-2"
            value={formData.username}
            onChange={handleChange}
            ref={usernameRef}
          />
          {errors.username && <p className="text-red-500">{errors.username}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="font-open-sans text-lg
           text-[#404040] block mb-2">
              Password
          </label>
          <input
            id="password"
            type="password"
            className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px] border
            border-[#CCCCCC] px-2"
            value={formData.password}
            onChange={handleChange}
            ref={passwordRef}
          />
          {errors.password && <p className="text-red-500">{errors.password}</p>}
        </div>
        <button
          type="submit"
          className="bg-[#004080] text-white w-[200px] h-[50px] rounded-[8px]
          font-poppins text-base font-bold hover:bg-[#003366] flex
          items-center justify-center"
        >
            Login
        </button>
        {errors.form && (
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700
             p-4 mt-8 w-full max-w-3xl"
            role="alert"
          >
            <p className="font-bold">Error</p>
            <p>{errors.form}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdminLogin;
