import React from 'react';
import {Link} from 'react-router-dom';
import projectImage1
  from '../../assets/tiago_page/projects_section/software_development.jpg';
import projectImage2
  from '../../assets/tiago_page/projects_section/oil_rig.jpg';
import projectImage3
  from '../../assets/tiago_page/projects_section/lulai.png';

const projects = [
  {
    title: 'Code Da Vinci',
    image: projectImage1,
    description: 'Code Da Vinci uses AI to turn user ' +
        'inputs into fully functional software, websites,' +
        ' and apps. It automates coding, deployment, and ' +
        'development, enabling users to create and refine ' +
        'digital products quickly and easily.',
    path: '/tiago/projects/code-da-vinci',
  },
  {
    title: 'ORD: Offshore Rigging Design',
    image: projectImage2,
    description: 'Offshore Rigging Design automates rigging ' +
        'calculations and CAD drawing generation, saving ' +
        'engineers time and reducing costs. It ensures ' +
        'compliance with industry standards, making offshore' +
        ' engineering more efficient.',
    path: '/tiago/projects/offshore-rigging',
  },
  {
    title: 'LulAI',
    image: projectImage3,
    description: 'LulAI enhances in-store shopping by providing' +
        ' personalized recommendations and real-time assistance ' +
        'through AI. It connects customers with products in nearby' +
        ' stores, while giving retailers valuable insights to ' +
        'optimize operations and boost sales.',
    path: '/tiago/projects/lulai',
  },
];

const ProjectsSection = () => {
  return (
    <section id="projects" className="py-20 bg-gray-50 text-gray-800">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center
         mb-12">Ongoing Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3
         gap-6 md:gap-8 px-8">
          {projects.map((project, index) => (
            <Link
              to={project.path}
              key={index}
              className="block bg-white rounded-lg shadow-md hover:shadow-lg
              transition-transform duration-300 transform hover:scale-105"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="relative overflow-hidden">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-40 sm:h-48 object-cover rounded-t-lg"
                />
              </div>
              <div className="p-4 md:p-6">
                <h3 className="text-lg md:text-xl font-bold mb-2">
                  {project.title}
                </h3>
                <p className="text-md text-gray-700">{project.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
