import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const ReceivedPage =
    ({title, message, image, buttonText, buttonLink}) => {
      return (
        <div className="flex flex-col items-center justify-center p-10 pt-32">
          <h1 className="font-poppins text-3xl font-bold text-[#333333] mb-5">
            {title}
          </h1>
          <p className="font-open-sans text-lg text-[#333333]
      text-center max-w-3xl mb-5">
            {message}
          </p>
          <img src={image} alt={title} className="w-[800px]
      h-[450px] object-cover mb-5" />
          <Link to={buttonLink} className="w-48 h-12 bg-[#004080]
       text-white rounded-lg font-poppins text-lg font-bold
       flex items-center justify-center hover:bg-[#003366]"
          onClick={() => window.scrollTo(0, 0)}>
            {buttonText}
          </Link>
        </div>
      );
    };

ReceivedPage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

export default ReceivedPage;
