import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import countryCodes from '../assets/phone-number-length-by-country-2024.json';

const timeZones = moment.tz.names().map((tz) => {
  const offset = moment.tz(tz).format('Z');
  return {
    name: tz,
    offset: `UTC${offset}`,
  };
});

const generateTimeOptions = () => {
  const times = [];
  const start = moment().startOf('day');
  const end = moment().endOf('day');

  while (start.isBefore(end)) {
    times.push(start.format('hh:mm A'));
    start.add(30, 'minutes');
  }

  return times;
};

const timeOptions = generateTimeOptions();

const RequestMeeting = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    countryCode: '+1',
    companyName: '',
    projectDetails: '',
    preferredDate: new Date(),
    availableFrom: '',
    availableTo: '',
    timeZone: '',
    meetingType: '',
    meetingPlace: '',
  });

  const [errors, setErrors] = useState({});
  const fullNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const countryCodeRef = useRef(null);
  const companyNameRef = useRef(null);
  const projectDetailsRef = useRef(null);
  const preferredDateRef = useRef(null);
  const availableFromRef = useRef(null);
  const availableToRef = useRef(null);
  const timeZoneRef = useRef(null);
  const meetingTypeRef = useRef(null);
  const meetingPlaceRef = useRef(null);

  const sortedCountryCodes = countryCodes.sort((a, b) => a.country
      .localeCompare(b.country));

  const handleChange = (e) => {
    const {id, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: '',
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      preferredDate: date,
    }));
  };

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 110,
        behavior: 'smooth',
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const errorRefs = [];

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full Name is required';
      errorRefs.push(fullNameRef);
    } else if (formData.fullName.length < 2 || formData.fullName.length > 100) {
      newErrors.fullName = 'Full Name must be between 2 and 100 characters.';
      errorRefs.push(fullNameRef);
    } else if (!/^[a-zA-ZÀ-ÿ\s'-]+$/.test(formData.fullName)) {
      newErrors.fullName = 'Full Name can only contain alphabetic characters,' +
          ' spaces, and hyphens.';
      errorRefs.push(fullNameRef);
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
      errorRefs.push(emailRef);
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
      errorRefs.push(emailRef);
    } else if (formData.email.length > 254) {
      newErrors.email = 'Email address is too long';
      errorRefs.push(emailRef);
    }

    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
      errorRefs.push(phoneRef);
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is invalid';
      errorRefs.push(phoneRef);
    } else {
      const selectedCountryCode = formData.countryCode
          .split(' ')[0];
      const selectedCountry = sortedCountryCodes.find((c) =>
        `+${c.phoneNumberLengthByCountry_CountryCode}` ===
          selectedCountryCode);

      if (selectedCountry) {
        if (selectedCountry.phoneNumberLengthByCountry_phLengthMin ===
            selectedCountry.phoneNumberLengthByCountry_phLengthMax) {
          if (formData.phone.length !== selectedCountry
              .phoneNumberLengthByCountry_phLengthMin) {
            newErrors.phone = `Phone number must be 
            ${selectedCountry
      .phoneNumberLengthByCountry_phLengthMin} digits long.`;
            errorRefs.push(phoneRef);
          }
        } else if (formData.phone.length < selectedCountry
            .phoneNumberLengthByCountry_phLengthMin ||
            formData.phone.length > selectedCountry
                .phoneNumberLengthByCountry_phLengthMax) {
          newErrors.phone = `Phone number must be between
           ${selectedCountry.phoneNumberLengthByCountry_phLengthMin}
            and ${selectedCountry.phoneNumberLengthByCountry_phLengthMax}
             digits long.`;
          errorRefs.push(phoneRef);
        }
      }
    }

    if (!formData.companyName.trim()) {
      newErrors.companyName = 'Company Name is required';
      errorRefs.push(companyNameRef);
    } else if (formData.companyName.length < 2 ||
        formData.companyName.length > 50) {
      newErrors.companyName = 'Company Name must be' +
          ' between 2 and 50 characters.';
      errorRefs.push(companyNameRef);
    } else if (!/^[a-zA-Z0-9\s&-]+$/.test(formData.companyName)) {
      newErrors.companyName = 'Company Name can only ' +
          'contain alphabetic characters, numeric characters, ' +
          'spaces, hyphens, and ampersands.';
      errorRefs.push(companyNameRef);
    }

    if (!formData.projectDetails.trim()) {
      newErrors.projectDetails = 'Project Details are required';
      errorRefs.push(projectDetailsRef);
    } else if (formData.projectDetails.length < 20 ||
        formData.projectDetails.length > 1000) {
      newErrors.projectDetails = 'Project Details must be' +
          ' between 20 and 1000 characters.';
      errorRefs.push(projectDetailsRef);
    }

    if (!formData.preferredDate) {
      newErrors.preferredDate = 'Preferred Date is required';
      errorRefs.push(preferredDateRef);
    }

    if (!formData.availableFrom) {
      newErrors.availableFrom = 'Available From time is required';
      errorRefs.push(availableFromRef);
    }

    if (!formData.availableTo) {
      newErrors.availableTo = 'Available To time is required';
      errorRefs.push(availableToRef);
    }

    if (!formData.timeZone) {
      newErrors.timeZone = 'Time Zone is required';
      errorRefs.push(timeZoneRef);
    }

    if (!formData.meetingType) {
      newErrors.meetingType = 'Meeting Type is required';
      errorRefs.push(meetingTypeRef);
    }

    if (formData.meetingType === 'In-Person' && !formData.meetingPlace.trim()) {
      newErrors.meetingPlace = 'Meeting Place is required' +
          ' for in-person meetings.';
      errorRefs.push(meetingPlaceRef);
    }

    setErrors(newErrors);

    if (errorRefs.length > 0) {
      scrollToRef(errorRefs[0]);
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/meeting-request`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          },
      );
      if (response.ok) {
        navigate('/meeting-request-received');
        window.scrollTo(0, 0);
      } else {
        const errorData = await response.json();
        setErrors({
          form: errorData.message || 'Failed to submit meeting request.',
        });
      }
    } catch (error) {
      console.error('Error submitting meeting request:', error);
      setErrors({
        form: error.message.includes('Failed to fetch') ?
            'The server is currently down. Please try again later.' :
            'An unexpected error occurred. Please try again later.',
      });
    }
  };

  return (
    <div className="flex flex-col items-center pt-32 px-5 mb-8">
      <h1 className="font-poppins text-3xl font-bold text-[#333333] mb-8">
          Get a Free Consultation
      </h1>
      <p className="font-open-sans text-lg text-[#404040]
      max-w-4xl mb-8 text-left">
          Schedule a free consultation with our experts to discuss
          your project needs and discover how we can help you achieve
          your business goals.
      </p>
      <h2 className="font-poppins text-2xl font-bold text-[#333333]
      mb-5 w-full max-w-4xl text-left">
          Schedule a Consultation
      </h2>
      <form className="max-w-4xl w-full" onSubmit={handleSubmit} noValidate>
        <div className="mb-4">
          <label
            htmlFor="fullName"
            className="font-open-sans text-lg text-[#404040] block mb-2"
          >
              Full Name
          </label>
          <input
            id="fullName"
            type="text"
            className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
             border border-[#CCCCCC] px-2"
            value={formData.fullName}
            onChange={handleChange}
            ref={fullNameRef}
          />
          {errors.fullName && (
            <p className="text-red-500">{errors.fullName}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="font-open-sans text-lg text-[#404040] block mb-2"
          >
              Email
          </label>
          <input
            id="email"
            type="email"
            className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
            border border-[#CCCCCC] px-2"
            value={formData.email}
            onChange={handleChange}
            ref={emailRef}
          />
          {errors.email && <p className="text-red-500">{errors.email}</p>}
        </div>
        <div className="flex flex-row mb-4">
          <div className="flex flex-col w-1/3 mr-2">
            <label
              htmlFor="countryCode"
              className="font-open-sans text-lg text-[#404040] block mb-2"
            >
                Country Code
            </label>
            <select
              id="countryCode"
              className="bg-[#E0E0E0] h-[40px] rounded-[5px]
               border border-[#CCCCCC] px-2 downward-dropdown"
              value={formData.countryCode}
              onChange={handleChange}
              ref={countryCodeRef}
            >
              <option value="">Select Country Code</option>
              {sortedCountryCodes.map((country) => (
                <option
                  key={`${country
                      .phoneNumberLengthByCountry_CountryCode}-
                      ${country.country}`}
                  value={`+${country
                      .phoneNumberLengthByCountry_CountryCode} 
                      ${country.country}`}
                >
                  {country.country} (+{country
                      .phoneNumberLengthByCountry_CountryCode})
                </option>
              ))}
            </select>
            {errors.countryCode && (
              <p className="text-red-500">{errors.countryCode}</p>
            )}
          </div>
          <div className="flex flex-col w-2/3">
            <label
              htmlFor="phone"
              className="font-open-sans text-lg text-[#404040] block mb-2"
            >
                Phone
            </label>
            <input
              id="phone"
              type="text"
              className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
               border border-[#CCCCCC] px-2"
              value={formData.phone}
              onChange={handleChange}
              ref={phoneRef}
            />
            {errors.phone && (
              <p className="text-red-500">{errors.phone}</p>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="companyName"
            className="font-open-sans text-lg text-[#404040] block mb-2"
          >
              Company Name
          </label>
          <input
            id="companyName"
            type="text"
            className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
            border border-[#CCCCCC] px-2"
            value={formData.companyName}
            onChange={handleChange}
            ref={companyNameRef}
          />
          {errors.companyName && (
            <p className="text-red-500">{errors.companyName}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="projectDetails"
            className="font-open-sans text-lg text-[#404040] block mb-2"
          >
              Project Details
          </label>
          <textarea
            id="projectDetails"
            className="bg-[#E0E0E0] w-full h-[120px] rounded-[5px]
             border border-[#CCCCCC] px-2 py-1 resize-none"
            value={formData.projectDetails}
            onChange={handleChange}
            ref={projectDetailsRef}
          ></textarea>
          {errors.projectDetails && (
            <p className="text-red-500">{errors.projectDetails}</p>
          )}
        </div>
        <div className="flex flex-row mb-4">
          <div className="flex flex-col w-1/4 mr-2">
            <label
              htmlFor="preferredDate"
              className="font-open-sans text-lg text-[#404040] block mb-2"
            >
                Preferred Date
            </label>
            <DatePicker
              id="preferredDate"
              selected={formData.preferredDate}
              onChange={handleDateChange}
              minDate={new Date()}
              className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
               border border-[#CCCCCC] px-2"
              ref={preferredDateRef}
            />
            {errors.preferredDate && (
              <p className="text-red-500">{errors.preferredDate}</p>
            )}
          </div>
          <div className="flex flex-col w-1/4 mr-2">
            <label
              htmlFor="availableFrom"
              className="font-open-sans text-lg text-[#404040] block mb-2"
            >
                Available From
            </label>
            <select
              id="availableFrom"
              className="bg-[#E0E0E0] h-[40px] rounded-[5px]
              border border-[#CCCCCC] px-2"
              value={formData.availableFrom}
              onChange={(e) =>
                handleChange({target: {id: 'availableFrom',
                  value: e.target.value}})}
              ref={availableFromRef}
            >
              <option value="">Select Time</option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
            {errors.availableFrom && (
              <p className="text-red-500">{errors.availableFrom}</p>
            )}
          </div>
          <div className="flex flex-col w-1/4 mr-2">
            <label
              htmlFor="availableTo"
              className="font-open-sans text-lg text-[#404040] block mb-2"
            >
                Available To
            </label>
            <select
              id="availableTo"
              className="bg-[#E0E0E0] h-[40px] rounded-[5px]
               border border-[#CCCCCC] px-2"
              value={formData.availableTo}
              onChange={(e) =>
                handleChange({target: {id: 'availableTo',
                  value: e.target.value}})}
              ref={availableToRef}
            >
              <option value="">Select Time</option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
            {errors.availableTo && (
              <p className="text-red-500">{errors.availableTo}</p>
            )}
          </div>
          <div className="flex flex-col w-1/4">
            <label
              htmlFor="timeZone"
              className="font-open-sans text-lg text-[#404040] block mb-2"
            >
                Time Zone
            </label>
            <select
              id="timeZone"
              className="bg-[#E0E0E0] h-[40px] rounded-[5px]
               border border-[#CCCCCC] px-2"
              value={formData.timeZone}
              onChange={handleChange}
              ref={timeZoneRef}
            >
              <option value="">Select Time Zone</option>
              {timeZones.map((zone) => (
                <option key={zone.name} value={zone.name}>
                  {zone.offset} - {zone.name}
                </option>
              ))}
            </select>
            {errors.timeZone && (
              <p className="text-red-500">{errors.timeZone}</p>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="meetingType"
            className="font-open-sans text-lg text-[#404040] block mb-2"
          >
              Meeting Type
          </label>
          <select
            id="meetingType"
            className="bg-[#E0E0E0] h-[40px] rounded-[5px]
             border border-[#CCCCCC] px-2"
            value={formData.meetingType}
            onChange={handleChange}
            ref={meetingTypeRef}
          >
            <option value="">Select Meeting Type</option>
            <option value="In-Person">In-Person</option>
            <option value="Zoom">Zoom</option>
            <option value="Microsoft Teams">Microsoft Teams</option>
            <option value="Google Meet">Google Meet</option>
          </select>
          {errors.meetingType && (
            <p className="text-red-500">{errors.meetingType}</p>
          )}
        </div>
        {formData.meetingType === 'In-Person' && (
          <div className="mb-4">
            <label
              htmlFor="meetingPlace"
              className="font-open-sans text-lg text-[#404040] block mb-2"
            >
                  Meeting Place
            </label>
            <input
              id="meetingPlace"
              type="text"
              className="bg-[#E0E0E0] w-full h-[40px]
              rounded-[5px] border border-[#CCCCCC] px-2"
              value={formData.meetingPlace}
              onChange={handleChange}
              ref={meetingPlaceRef}
            />
            {errors.meetingPlace && (
              <p className="text-red-500">{errors.meetingPlace}</p>
            )}
          </div>
        )}
        <button
          type="submit"
          className="bg-[#004080] text-white w-[200px]
          h-[50px] rounded-[8px] font-poppins text-base
          font-bold hover:bg-[#003366] mb-8 flex
          items-center justify-center"
        >
            Request Consultation
        </button>
      </form>
      {errors.form && (
        <div
          className="bg-red-100 border-l-4 border-red-500
          text-red-700 p-4 w-full max-w-4xl"
          role="alert"
        >
          <p className="font-bold">Error</p>
          <p>{errors.form}</p>
        </div>
      )}
    </div>
  );
};

export default RequestMeeting;
