import React from 'react';
import {Link} from 'react-router-dom';
import arrowRight from '../../assets/services_page/arrow-right-solid.svg';
import '../../styles/index.css';

const Card = ({title, image, description, reverse, technologies}) => {
  const formattedTitle = title.toLowerCase().split(' ').join('-');

  return (
    <div className={`my-12 lg:flex h-auto justify-between
    ${reverse ? 'flex-row-reverse' : ''}`}>
      {/* IMAGE */}
      <div className={`${reverse ? 'lg:ml-6 xl:ml-16' : 'lg:mr-6 xl:mr-16'}`}>
        <img src={image} alt={title} className='lg:w-[500px]
        rounded-md drop-shadow-md border-neutral-200/50 mb-5 lg:mb-0
        lg:h-full' />
      </div>
      {/* TEXT SECTION */}
      <div className="flex flex-col justify-center lg:w-[50%] xl:w-[60%]">
        <div className='font-poppins text-3xl lg:text-4xl
        font-bold text-[#2e2e2e] leading-7 tracking-tight mb-5'>
          {title}
        </div>
        {/* <div className="w-full mb-2">
          <div className='flex mt-2 items-center'>
            <Link to="/portfolio"
              className='text-[#3B82F6] hover:underline text-md'>
              View Projects
            </Link>
            <svg aria-hidden="true" className="text-[#3B82F6] w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414
              1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1
              1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
            </svg>
          </div>
        </div> */}
        <div className='text-md md:text-lg lg:text-md xl:text-lg tracking-tight
        font-normal leading-6 text-gray-500 mb-3 lg:text-justify
        text-left w-full'>
          {description}
        </div>
        {/* DEV ICONS */}
        <div className="w-full mb-3 flex items-center gap-3">
          {technologies.map((tech, index) => (
            <div key={index} className="rounded-md hover:bg-[#555555]
            hover:bg-opacity-20 icon-wrapper"
            style={{width: tech.width, height: tech.height,
              padding: tech.padding}}>
              <img src={tech.src} alt={tech.alt} className="h-full w-full
              object-contain" />
              <span className="tooltip-text text-sm">{tech.alt}</span>
            </div>
          ))}
        </div>
        <Link to={`/services/${formattedTitle}`}
          className="py-2 px-4 flex items-center justify-center gap-2
          bg-[#004080] text-[#FFFFFF] text-sm font-semibold rounded-full
          duration-200 max-w-36 hover:bg-blue-700
          hover:[box-shadow:rgb(183,219,255)_3px_3px]"
          onClick={() => window.scrollTo(0, 0)}>
          Learn More
          <img src={arrowRight} alt="Arrow Right" className="h-3" />
        </Link>
      </div>
    </div>
  );
};

export default Card;
