import React from 'react';
import {Link} from 'react-router-dom';
import imagePortugal from '../../assets/tiago_page/portugal.jpg';
import imageNorway from '../../assets/tiago_page/norway.jpg';
import imageUniversity from '../../assets/tiago_page/ntnu.jpg';
import imageCanada from '../../assets/tiago_page/canada.jpg';
import imageBritoConsulting from '../../assets/tiago_page/brito-consulting.png';

// Milestone data
const milestones = [
  {
    year: '2003',
    title: 'Birth and Early Life in Portugal',
    image: imagePortugal,
    path: '/tiago/milestones/portugal',
  },
  {
    year: '2010',
    title: 'Educational Journey in Norway',
    image: imageNorway,
    path: '/tiago/milestones/norway',
  },
  {
    year: '2023',
    title: 'Key Moments in University',
    image: imageUniversity,
    path: '/tiago/milestones/university',
  },
  {
    year: '2024',
    title: 'Moving to Canada',
    image: imageCanada,
    path: '/tiago/milestones/canada',
  },
  {
    year: '2024',
    title: 'Founding Brito Consulting',
    image: imageBritoConsulting,
    path: '/tiago/milestones/brito-consulting',
  },
];

const JourneyTimeline = () => {
  return (
    <section id="journey" className="py-10 md:py-20 bg-gray-50">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold
        text-center mb-8 md:mb-12">
            My Journey to Entrepreneurship
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2
        lg:grid-cols-3 gap-6 md:gap-8 px-8">
          {milestones.map((milestone, index) => (
            <Link
              to={milestone.path}
              key={index}
              className="block bg-white rounded-lg shadow-md hover:shadow-lg
              transition-transform duration-300 transform hover:scale-105"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="relative overflow-hidden">
                <img
                  src={milestone.image}
                  alt={milestone.title}
                  className="w-full h-40 sm:h-48 object-cover rounded-t-lg"
                />
              </div>
              <div className="p-4 md:p-6">
                <h3 className="text-lg md:text-xl font-bold mb-2">
                  {milestone.year}
                </h3>
                <h4 className="text-md md:text-lg font-semibold text-gray-700">
                  {milestone.title}
                </h4>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default JourneyTimeline;
