import heroBackground from '../../assets/home_page/background.png';
import textBackground from '../../assets/home_page/textBackground.png';
import {Link} from 'react-router-dom'; // Import Link from react-router-dom

const HeroSection = () => {
  return (
    <section className="relative pt-20
    sm:pt-36 md:pt-12 bg-center bg-cover
    lg:pt-32 min-h-[800px] sm:min-h-[950px]"
    style={{backgroundImage: `url(${heroBackground})`}}
    >
      <div className="mx-auto w-full max-w-7xl px-5 pt-16 pb-3
      md:px-10 md:py-24">
        <div className="mx-auto mb-8 w-full max-w-4xl
        text-center md:mb-16 lg:mb-20">
          <h1 className="mb-4 text-4xl font-semibold md:text-7xl
          sm:text-5xl text-[#004080]">
              The Website You Want Without The
            <span className="bg-cover bg-center px-4 font-bold text-white"
              style={{backgroundImage: `url(${textBackground})`}}>
              Dev Time</span>.
          </h1>
          <p className="mx-auto mb-5 max-w-[628px] text-xl text-[#636262]
          lg:mb-8 mt-5 md:mt-10">
              Innovative Software, Websites, and Mobile Apps Delivered Fast
          </p>
          <div className="flex justify-center">
            <Link
              to='/request-meeting' // Link to the request meeting page
              className="flex max-w-full flex-row items-center
            justify-center rounded-full border border-solid border-[#004080]
            px-4 py-3 sm:px-6 sm:py-4 lg:px-8 lg:py-5 font-semibold
            text-[#004080] duration-200 cursor-pointer
            hover:[box-shadow:rgb(183,219,255)_6px_6px] text-lg"
            >
              <img
                src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63905a575ec39b6784fc687c_Play.svg"
                alt=""
                className="mr-2 inline-block w-6"
              />
              <p className="text-black">
                  Get a Free Consultation
              </p>
            </Link>
          </div>
        </div>
      </div>
      <img
        src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63905b9f809b5c8180ce30c5_pattern-1.svg"
        alt=""
        className="opacity-20 absolute bottom-10 left-0 right-auto top-auto-
        -z-50 inline-block md:bottom-1/2 md:left-20 md:right-auto md:top-10"
      />
      <img
        src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63905b9f809b5c8180ce30c5_pattern-1.svg"
        alt=""
        className="opacity-20 hidden sm:absolute sm:bottom-40 sm:left-auto
        right-70 -z-50 sm:inline-block md:bottom-1/2 md:right-10
        md:left-auto md:-top-80-"
      />
    </section>
  );
};

export default HeroSection;
