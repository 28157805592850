import React, {useState} from 'react';
import NewsArticleCard from '../components/news_page/NewsArticleCard';
import image1 from '../assets/news_page/image1.webp';
import image2 from '../assets/news_page/image2.png';
import image3 from '../assets/news_page/image3.jpg';
import searchIcon from '../assets/news_page/magnifying-glass-solid.png';
import checkIcon from '../assets/news_page/check-solid.png';

const newsArticles = [
  {
    title: 'Brito Consulting Expands to New Office in Toronto',
    image: image1,
    description: 'Brito Consulting is excited to ' +
        'announce the opening of our new office in' +
        ' Toronto. This expansion allows us to ' +
        'better serve our clients in North America ' +
        'and continue our growth in the tech industry.',
    link: '/news/brito-consulting-expands-to-new-office-in-toronto',
  },
  {
    title: 'Top 5 Emerging Technologies in Software Development for 2024',
    image: image2,
    description: 'As we look ahead to 2024, several ' +
        'emerging technologies are set to transform ' +
        'the software development landscape. In this' +
        ' article, we explore the top five technologies' +
        ' that developers and businesses should keep an eye on.',
    link: '/news/top-5-emerging-technologies-in-software-development-for-2024',
  },
  {
    title: 'Join Us at the 2024 Tech Innovators Conference',
    image: image3,
    description: 'Brito Consulting will be attending ' +
        'the 2024 Tech Innovators Conference. Join us ' +
        'to explore the latest in technology innovation ' +
        'and network with industry leaders. Here\'s' +
        ' what you can expect from the event...',
    link: '/news/join-us-at-the-2024-tech-innovators-conference',
  },
];

const News = () => {
  const [selectedCategories, setSelectedCategories] = useState([
    'Company Announcements',
    'Industry Insights',
    'Events',
  ]);

  const handleCategoryClick = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories
          .filter((cat) => cat !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const categories = [
    'Company Announcements',
    'Industry Insights',
    'Case Studies',
    'Educational Articles',
    'Events',
  ];

  return (
    <div className="flex flex-col items-center pt-20 md:pt-32
     mb-10 px-4 sm:px-6">
      <h1 className="font-poppins text-2xl md:text-3xl font-bold
      text-[#333333] mb-6 md:mb-8">
          News
      </h1>
      <div className="w-full max-w-4xl">
        <div className="relative w-full mb-5">
          <input
            type="text"
            placeholder="Search articles..."
            className="w-full p-3 border bg-[#E0E0E0] border-[#CCCCCC]
             rounded-2xl placeholder-gray-500 focus:outline-none
              focus:ring-2 focus:ring-[#004080]"
          />
          <img src={searchIcon} alt="Search"
            className="absolute right-3 top-1/2 transform
            -translate-y-1/2 w-5 h-5 md:w-6 md:h-6" />
        </div>
        <div className="flex flex-wrap gap-2 mb-5 justify-center
         sm:justify-start">
          {categories.map((category) => (
            <button
              key={category}
              className={`px-3 py-2 rounded-lg font-poppins 
              text-sm md:text-base flex items-center gap-2 
              transition-colors duration-200 ${
                        selectedCategories.includes(category) ?
                            'bg-[#004080] text-white' :
                            'bg-[#E0E0E0] text-[#004080]'
            }`}
              onClick={() => handleCategoryClick(category)}
            >
              {selectedCategories.includes(category) && (
                <img src={checkIcon} alt="Selected" className="w-4 h-4" />
              )}
              {category}
            </button>
          ))}
        </div>
        <h2 className="font-poppins text-xl md:text-2xl font-bold
         text-[#333333] mb-4 md:mb-5 text-left">
            Recent Articles
        </h2>
        <div className="space-y-6 md:space-y-8">
          {newsArticles.map((article, index) => (
            <NewsArticleCard
              key={index}
              title={article.title}
              image={article.image}
              description={article.description}
              link={article.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
