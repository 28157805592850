import tiagoBritoImage from '../../assets/home_page/tiago-brito.jpg';
import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {COMPANY_ADDRESS, CONTACT_NUMBER} from '../../config';

const ContactSection = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const fullNameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const handleChange = (e) => {
    const {id, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: '',
    }));
  };

  const restrictInput = (e, pattern) => {
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const errorRefs = [];

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full Name is required';
      errorRefs.push(fullNameRef);
    } else if (formData.fullName.length < 2 || formData.fullName.length > 100) {
      newErrors.fullName = 'Full Name must be between 2 and 100 characters.';
      errorRefs.push(fullNameRef);
    } else if (!/^[a-zA-ZÀ-ÿ\s'-]+$/.test(formData.fullName)) {
      newErrors.fullName =
                'Full Name can only contain alphabetic characters, ' +
                'spaces, and hyphens.';
      errorRefs.push(fullNameRef);
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
      errorRefs.push(emailRef);
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
      errorRefs.push(emailRef);
    } else if (formData.email.length > 254) {
      newErrors.email = 'Email address is too long';
      errorRefs.push(emailRef);
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      errorRefs.push(messageRef);
    } else if (formData.message.length < 10 || formData.message.length > 1000) {
      newErrors.message = 'Message must be between 10 and 1000 characters.';
      errorRefs.push(messageRef);
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/contact-section`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
      if (response.ok) {
        navigate('/inquiry-received');
        window.scrollTo(0, 0);
      } else {
        const errorData = await response.json();
        setErrors({
          form: errorData.message || 'Failed to submit inquiry.',
        });
      }
    } catch (error) {
      console.error('Error submitting inquiry:', error);
      setErrors({
        form: error.message.includes('Failed to fetch') ?
                    'The server is currently down. Please try again later.' :
                    'An unexpected error occurred. Please try again later.',
      });
    }
  };

  return (
    <section className="relative isolate bg-[#FFFFFF] py-10">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="px-6 pb-5 pt-10 md:pt-16 lg:px-8 lg:py-16">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg text-center">
            <div className="absolute inset-y-0 left-0 -z-10
            w-full overflow-hidden
          ring-gray-300/10 lg:w-1/2">
            </div>
            <h2 className="text-3xl xl:text-5xl font-bold tracking-tight
            text-[#004080] text-center lg:text-left mb-5
            drop-shadow-sm">Contact Us</h2>
            <p className="mb-10 text-lg leading-6 text-gray-600
            text-center lg:text-left">
              Feel free to reach out to us for any inquiries or
              assistance.
            </p>
            {/* Tiago Brito */}
            <dl className="space-2 text-base text-gray-600 md:flex
            items-center w-full justify-between xl:justify-normal">
              <img
                src={tiagoBritoImage}
                alt="Tiago Brito"
                className="flex m-auto md:mx-16 mb-3 lg:m-0"
                style={{width: '150px', height: '200px', borderRadius: '50%'}}
              />
              <div className='grid justify-center xl:ml-16 lg:ml-10'>
                <div className="grid justify-center xl:justify-between
                mb-4 md:justify-start">
                  <h2 className='font-bold text-2xl flex
                  xl:text-2xl'>Tiago Brito</h2>
                  <span className='flex text-sm xl:text-md m-auto
                  md:m-0'>Founder & CEO</span>
                </div>
                <div className="flex gap-x-4 items-center
                mb-1 md:justify-start">
                  <dt>
                    <span className="sr-only">Email</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="h-7 w-6 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25
                        2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5
                        4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25
                        2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36
                        0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75">
                      </path>
                    </svg>
                  </dt>
                  <dd>
                    <a className="text-sm flex justify-center"
                      href={`mailto:${'tiago@britoconsult.com'}`}>
                      {'tiago@britoconsult.com'}
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4 items-center
                mb-1">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="h-7 w-6 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75
                        6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75
                        3h.75M6.75 21v-3.375c0-.621.504-1.125
                        1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3
                        3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0
                        3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                      ></path>
                    </svg>
                  </dt>
                  <dd className='flex text-sm text-left'>
                    {COMPANY_ADDRESS}
                  </dd>
                </div>
                <div className="flex gap-x-4 items-center
                mb-2 md:justify-start">
                  <dt className="flex-none">
                    <span className="sr-only">Number</span>
                    <svg xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="-2 -2 21 21"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="h-7 w-6 text-gray-500 flex justify-center
                      text-center">
                      <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1
                      2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zm6
                      11a1 1 0 1 0-2 0 1 1 0 0 0 2 0"/>
                    </svg>
                  </dt>
                  <dd className='text-sm'>{CONTACT_NUMBER}</dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
        {/* Forms */}
        <form className="px-6 pb-16 md:pb-32 lg:px-8 lg:py-16"
          onSubmit={handleSubmit} noValidate>
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-y-6">
              <div>
                <h2 className='text-3xl xl:text-5xl font-bold
            text-[#004080] text-center lg:text-left mb-5
            drop-shadow-sm'>Send Inquiry</h2>
                <label htmlFor="first-name" className="block text-lg
                leading-6 text-[#404040] font-open-sans">
                  Full Name
                </label>
                <div className="mt-2.5">
                  <input
                    id="fullName"
                    type="text"
                    autoComplete="given-name"
                    placeholder="Full Name"
                    className="block w-full rounded-md border-0 px-3.5
                    py-2 text-gray-900 shadow-sm ring-1 ring-inset
                    ring-gray-300 placeholder:text-gray-400 focus:ring-2
                    focus:ring-inset focus:ring-indigo-600 sm:text-sm
                    sm:leading-6 bg-[#E0E0E0]"
                    name="firstName"
                    value={formData.fullName}
                    onChange={handleChange}
                    ref={fullNameRef}
                    onKeyPress={(e) => restrictInput(e, /^[a-zA-ZÀ-ÿ\s'-]$/)}
                  />
                  {errors.fullName && <p className="text-red-500 text-left
                  w-full md:w-96">{errors.fullName}</p>}
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-lg
                leading-6 text-[#404040] font-open-sans">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Your Email"
                    className="block w-full rounded-md border-0 px-3.5 py-2
                    text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                    placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                    focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-[#E0E0E0]"
                    value={formData.email}
                    onChange={handleChange}
                    ref={emailRef}
                  />
                  {errors.email && <p className="text-red-500 text-left
                  w-full md:w-96">{errors.email}</p>}
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-lg font-open-sans
                leading-6 text-[#404040]">
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    id="message"
                    rows="3"
                    placeholder="Your Message"
                    className="block w-full rounded-md border-0 px-3.5 py-2
                    text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                    placeholder:text-gray-400 sm:text-sm sm:leading-6
                    focus:ring-indigo-600 focus:ring-2 focus:ring-inset
                    resize-none bg-[#E0E0E0]"
                    value={formData.message}
                    onChange={handleChange}
                    ref={messageRef}
                  ></textarea>
                  {errors.message && <p className="text-red-500 text-left
                  w-full md:w-96">{errors.message}</p>}
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="bg-[#004080] text-[#FFFFFF] rounded-[8px] py-2 px-4
                font-poppins text-base hover:bg-blue-700 duration-200
                hover:drop-shadow-lg font-bold w-full"
              >
                Submit Inquiry
              </button>
            </div>
          </div>
        </form>
      </div>
      {errors.form && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700
          p-4 mt-8 w-full max-w-4xl mx-auto"
          role="alert"
        >
          <p className="font-bold">Error</p>
          <p>{errors.form}</p>
        </div>
      )}
    </section>
  );
};

export default ContactSection;
