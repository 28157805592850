import React from 'react';
import readingImage from '../../assets/tiago_page/reading.jpg';
import travelImage from '../../assets/tiago_page/travel.jpg';
import gymImage from '../../assets/tiago_page/gym.jpg';
import learningImage from '../../assets/tiago_page/learning.jpg';

const InterestsAndPassions = () => {
  const paragraph1 = (
    <>
            One of my core passions is reading, where I immerse myself in a
            wide range of subjects including philosophy, science, history,
            and classic literature. This continuous learning fuels my
            intellectual curiosity and keeps me inspired to innovate and think
            critically.
    </>
  );

  const paragraph2 = (
    <>
            Traveling is another significant aspect of my life. I love
            exploring new cultures, experiencing different environments, and
            gaining fresh perspectives from around the world. Traveling not
            only broadens my horizons but also enriches my understanding of
            global markets and trends, which I apply to my business ventures.
    </>
  );

  const paragraph3 = (
    <>
            A key component of my daily routine is my commitment to going to
            the gym. Fitness plays an essential role in keeping my mind and
            body in peak condition, enabling me to tackle challenges with
            energy and focus. The discipline and resilience I cultivate in the
            gym translate directly into my professional life, driving me to
            push boundaries and achieve my goals.
    </>
  );

  const paragraph4 = (
    <>
            My passion for continuous learning is at the heart of everything I
            do. Whether it’s acquiring new skills, staying updated with the
            latest in technology and business, or exploring new areas of
            knowledge, I am always striving to expand my understanding and
            improve myself. This relentless pursuit of knowledge is what keeps
            me at the forefront of innovation and personal growth.
    </>
  );
  return (
    <section id="interests-passions" className="py-20 bg-white text-gray-800">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12">
                    Interests and Passions
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-8">
          <div className="flex flex-col items-center text-left">
            <img
              src={readingImage}
              alt="Reading"
              className="w-full h-64 rounded-lg shadow-md mb-4 object-cover"
            />
            <h3 className="text-2xl font-bold mb-2">Reading</h3>
            <p className="text-lg text-gray-600">{paragraph1}</p>
          </div>
          <div className="flex flex-col items-center text-left">
            <img
              src={travelImage}
              alt="Traveling"
              className="w-full h-64 rounded-lg shadow-md mb-4 object-cover"
            />
            <h3 className="text-2xl font-bold mb-2">Traveling</h3>
            <p className="text-lg text-gray-600">{paragraph2}</p>
          </div>
          <div className="flex flex-col items-center text-left">
            <img
              src={gymImage}
              alt="Going to the Gym"
              className="w-full h-64 rounded-lg shadow-md mb-4 object-cover"
            />
            <h3 className="text-2xl font-bold mb-2">Going to the Gym</h3>
            <p className="text-lg text-gray-600">{paragraph3}</p>
          </div>
          <div className="flex flex-col items-center text-left">
            <img
              src={learningImage}
              alt="Continuous Learning"
              className="w-full h-64 rounded-lg shadow-md mb-4 object-cover"
            />
            <h3 className="text-2xl font-bold mb-2">Continuous Learning</h3>
            <p className="text-lg text-gray-600">{paragraph4}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InterestsAndPassions;
