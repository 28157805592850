import React from 'react';
import {Link as ScrollLink} from 'react-scroll';
import heroImage from '../../assets/tiago_page/hero.jpg';

const HeroSection = () => {
  const title = (
    <>
          Meet Tiago Brito
    </>
  );

  const subtitle = (
    <>
          Entrepreneur, Innovator, Visionary
    </>
  );

  const paragraph1 = (
    <>
          Founder of Brito Consulting, driven by a passion
          for technology, a relentless pursuit of knowledge,
          and a commitment to excellence.
    </>
  );
  return (
    <section
      className="relative flex items-center justify-center h-screen
       bg-cover bg-center text-white"
      style={{backgroundImage: `url(${heroImage})`}}
    >
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="relative text-center px-6 md:px-12 mt-0">

        <h1 className="mt-20 font-poppins text-3xl md:text-4xl font-bold
          tracking-tight text-[#FFFFFF] lg:text-6xl drop-shadow-sm">
          {title}
        </h1>
        <p className="text-sm mt-3 font-open-sans font-bold uppercase
            tracking-widest text-white">
          {subtitle}
        </p>
        <p className="mx-auto mt-2 max-w-7xl text-md md:text-lg
           font-open-sans text-white drop-shadow-md
            lg:text-xl lg:leading-8 mb-6">
          {paragraph1}
        </p>
        <ScrollLink
          to="personal-intro"
          smooth={true}
          duration={1000}
          className="text-lg bg-blue-600 hover:bg-blue-700 py-3
          px-8 rounded-full cursor-pointer"
        >
                Learn More
        </ScrollLink>
      </div>
    </section>
  );
};

export default HeroSection;
