import React from 'react';
import imageStartHack from '../../../assets/tiago_page/hackathon.jpg';

const UniversityMilestone = () => {
  const paragraph1 = (
    <>
        One of the most pivotal moments in my university
        journey was participating in Trondheim&apos;s largest
        student-organized hackathon, Start Code, from
        October 27th to 29th, 2023. Despite finding out
        about the event only a week before it started,
        I managed to form a team by reaching out to my
        teaching assistant coworkers, Torgrim Thorsen
        and Martin Hegnum Johannessen, who both joined
        me along with Martin&apos;s classmate, Kjetil Indrehus.
    </>
  );

  const paragraph2 = (
    <>
        Our challenge was to create a mobile application
        for Food Manager AS that featured a voice assistant,
        AI-generated meal suggestions, and a receipt-scanning
        feature using OCR to extract groceries bought. Despite
        the tough competition, we were thrilled to be
        announced as the winners of the case during the
        after-party. This victory was a testament to our
        teamwork and determination.
    </>
  );

  const paragraph3 = (
    <>
            As a prize, we received tickets to participate
            in START Global&apos;s Start Hack, Europe&apos;s Most
            Entrepreneurial Hackathon. Unfortunately, Kjetil
            and Martin were unable to attend, so Ulrik Hesmyr
            and Teemu Johannes Vikøren joined the team for
            Start Hack in St. Gallen, Switzerland. Start Hack
            was on a completely different scale, with 600 hackers
            and 36 hours to create a solution for one of the
            nine case partners.
    </>
  );

  const paragraph4 = (
    <>
            Ultimately, we chose the United Nations&apos;
            G20 Global Land Initiative case, focusing on
            locating and managing wildfires in Brazil. This
            decision unified our team, leading us to win
            the case competition. Presenting our solution
            on the main stage of Start Summit to over 5000
            people was a challenging but rewarding experience
            that taught me invaluable lessons in public
            speaking and leadership.
    </>
  );

  return (
    <section className="py-10 md:py-20 bg-gray-50 text-gray-800 px-8">
      <div className="container mx-auto">
        <img src={imageStartHack} alt="Start Hack 2024"
          className="w-full h-64 md:h-96 object-cover rounded-lg mb-8"/>
        <h2 className="text-3xl md:text-4xl
                font-bold mb-6">Key Moments in University</h2>
        <p className="text-lg md:text-xl
                leading-relaxed">{paragraph1}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph2}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph3}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph4}</p>
      </div>
    </section>
  );
};

export default UniversityMilestone;
