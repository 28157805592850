import React from 'react';
import {Link} from 'react-router-dom';
import Card from '../components/services_page/Card';
// IMAGES
import imageSoftwareService from '../assets/services_page/image1.jpg';
import imageWebCreationService from '../assets/services_page/image2.jpg';
import imageServiceMobile from '../assets/services_page/image3.jpg';
import ourServices from '../assets/services_page/ourServices.jpg';
// ICONS
import reactLogo from '../assets/services_page/reactLogo.png';
import tailwindLogo from '../assets/services_page/tailwindLogo.png';
import JSLogo from '../assets/services_page/JSLogo.png';
import nodeJSLogo from '../assets/services_page/nodeJSLogo.png';
import htmlLogo from '../assets/services_page/htmlLogo.png';
import cssLogo from '../assets/services_page/cssLogo.png';
import kotlinLogo from '../assets/services_page/kotlinLogo.png';
import swiftLogo from '../assets/services_page/swiftLogo.png';
import pythonLogo from '../assets/services_page/pythonLogo.png';

const services = [
  {
    title: 'Software Development',
    image: imageSoftwareService,
    description: 'Tailored software solutions designed to meet'+
    ' specific business needs, ensuring seamless integration and'+
    ' scalable performance. Our approach focuses on creating custom'+
    ' software that not only addresses your current challenges but'+
    ' also adapts to future growth and technological advancements.',
    technologies: [
      {src: htmlLogo, width: 40, height: 40, padding: 7, alt: 'HTML5'},
      {src: cssLogo, width: 40, height: 40, padding: 7, alt: 'CSS3'},
      {src: JSLogo, width: 40, height: 40, padding: 6, alt: 'JavaScript'},
      {src: nodeJSLogo, width: 40, height: 40, padding: 6, alt: 'NodeJS'},
      {src: pythonLogo, width: 40, height: 40, padding: 6, alt: 'Python'},
    ],
  },
  {
    title: 'Website Development',
    image: imageWebCreationService,
    description: 'From corporate websites to e-commerce platforms,'+
    ' we craft engaging, responsive designs that drive conversions and'+
    ' enhance user experience. Our design philosophy prioritizes the'+
    ' needs of the end user, ensuring that every interaction is intuitive'+
    ' and seamless. By incorporating the latest web technologies and'+
    ' design trends, we create visually stunning websites that not only'+
    ' capture the attention of visitors but also encourage them to take'+
    ' action.',
    reverse: true,
    technologies: [
      {src: htmlLogo, width: 40, height: 40, padding: 7, alt: 'HTML5'},
      {src: cssLogo, width: 40, height: 40, padding: 7, alt: 'CSS3'},
      {src: JSLogo, width: 40, height: 40, padding: 6, alt: 'JavaScript'},
      {src: tailwindLogo, width: 40, height: 40, padding: 0, alt: 'Tailwind'},
      {src: reactLogo, width: 40, height: 40, padding: 0, alt: 'React'},
      {src: nodeJSLogo, width: 40, height: 40, padding: 6, alt: 'NodeJS'},
    ],
  },
  {
    title: 'Mobile App Development',
    image: imageServiceMobile,
    description: 'Build powerful mobile applications for iOS and'+
    ' Android that offer exceptional performance and intuitive user'+
    ' interfaces. Our development process focuses on creating robust'+
    ' and scalable apps that cater to the unique needs of your business'+
    ' and users. We leverage the latest mobile technologies and'+
    ' frameworks to ensure your app is not only high-performing but'+
    ' also future-proof.',
    technologies: [
      {src: htmlLogo, width: 40, height: 40, padding: 7, alt: 'HTML5'},
      {src: cssLogo, width: 40, height: 40, padding: 7, alt: 'CSS3'},
      {src: reactLogo, width: 40, height: 40, padding: 0, alt: 'React Native'},
      {src: kotlinLogo, width: 40, height: 40, padding: 7, alt: 'Kotlin'},
      {src: swiftLogo, width: 40, height: 40, padding: 6, alt: 'Swift'},
    ],
  },
];

const Services = () => {
  return (
    <section className="flex flex-col items-center">
      {/* HERO SECTION */}
      <section className="relative text-center py-32 bg-cover bg-center
      bg-no-repeat text-[#FFFFFF] drop-shadow-md border-neutral-200/50
      w-full mb-10"
      style={{backgroundImage: `url(${ourServices})`}}>
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0"></div>
        <div className="relative py-8 px-4 rounded-md">
          <h1 className="font-poppins text-3xl lg:text-6xl md:text-4xl
          font-bold text-[#FFFFFF] drop-shadow-md mt-10 tracking-tight">
            Our Services
          </h1>
          <p className="mt-3 font-open-sans text-xl drop-shadow-md">
            Discover the dedication and innovation behind Brito Consulting
          </p>
        </div>
      </section>
      {/* INTRODUCTORY TEXT */}
      <section className='w-full max-w-7xl px-4 lg:px-8 my-5'>
        <h2 className="font-poppins text-3xl tracking-tight
        font-bold text-[#2e2e2e] leading-7 mb-4">
          Achieve Your Goals with Brito Consulting
        </h2>
        <p className='text-md md:text-lg lg:text-md xl:text-lg tracking-tight
        font-normal leading-6 text-gray-500 lg:text-justify
        text-left w-full'>
          Brito Consulting provides innovative software solutions
          tailored to meet your business needs, ensuring seamless
          integration and scalability. Our team is dedicated to helping
          you achieve your goals by providing‏‏‎ ‎
          <Link to={`/services/software-development`}
            className='text-[#3B82F6]'
            onClick={() => window.scrollTo(0, 0)}>
            <b className='hover:underline font-normal'>software</b>
          </Link>,
          <Link to={`/services/website-development`}
            className='text-[#3B82F6]'
            onClick={() => window.scrollTo(0, 0)}>
            ‏‏‎ ‎
            <b className='hover:underline font-normal'>website</b>
          </Link>, and
          <Link to={`/services/mobile-app-development`}
            className='text-[#3B82F6]'
            onClick={() => window.scrollTo(0, 0)}>
            ‏‏‎ ‎
            <b className='hover:underline font-normal'>
              mobile app</b>
          </Link> development
          that are not only functional but are also future-proof.
        </p>
      </section>
      <section className='border-b my-5 w-52'></section>
      {/* SERVICES SECTION */}
      <section className="grid mx-auto w-full max-w-7xl px-4 lg:px-8">
        {services.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            image={service.image}
            description={service.description}
            reverse={service.reverse}
            technologies={service.technologies}
          />
        ))}
      </section>
    </section>
  );
};

export default Services;
