import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const ServiceCard = ({icon, title, description}) => {
  const formattedTitle = title.toLowerCase().split(' ').join('-');

  return (
    <div className="bg-white p-7 text-center
    mx-auto px-8 rounded-xl border drop-shadow-md border-neutral-200/50">
      <div className="mb-4 flex justify-center">{icon}</div>
      <h3 className="font-poppins text-2xl font-bold mb-2
      lg:h-10 flex items-center justify-center">
        {title}
      </h3>
      <p className="mt-1.5 text-md text-secondary-500
            mb-7 text-center font-normal text-md leading-relaxed
          text-gray-500 max-md:text-center max-w-2xl mx-auto">
        {description}
      </p>
      <Link
        to={`/services/${formattedTitle}`}
        className="bg-[#004080] text-[#FFFFFF] rounded-[8px] py-2 px-4
          font-poppins text-base hover:bg-blue-700 duration-200
          hover:drop-shadow-md hover:border-neutral-200/50 font-bold"
        onClick={() => window.scrollTo(0, 0)}
      >
        Learn More
      </Link>
    </div>
  );
};

ServiceCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ServiceCard;
