import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import logo from '../assets/home_page/brito-consulting-logo.png';
import {useLocation} from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleClick = () => {
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToProjects = () => {
    if (location.pathname !== '/tiago') {
      navigate('/tiago');
      setTimeout(() => {
        document.getElementById('projects')
            .scrollIntoView({behavior: 'smooth'});
      }, 0);
    } else {
      document.getElementById('projects')
          .scrollIntoView({behavior: 'smooth'});
    }
  };

  const navLinks = [
    {to: '/services', label: 'Services'},
    {label: 'Projects', action: scrollToProjects},
    {to: '/about', label: 'About Us'},
    {to: '/news', label: 'News'},
    {to: '/contact', label: 'Contact'},
  ];

  return (
    <div
      className={`px-5 w-full fixed top-0 left-0 h-20
        max-sm:pr-7 z-[50] bg-[#ffffff] shadow-md ${
              isScrolled ? 'shadow-md bg-[#ffffff]' : 'shadow-none'
    }`}
    >
      <header className="h-full" id="top">
        <div className="h-full">
          <div
            id="row"
            className="flex justify-between items-center max-w-7xl
            mx-auto lg:px-4 xl:px-8 h-full"
          >
            {/* LOGO */}
            <div className="" id="logo">
              <Link to="/" className="flex items-center
              no-underline" onClick={handleClick}>
                <img src={logo} alt="Brito Consulting Logo"
                  className="w-[40px] mr-4" />
                <h1
                  className="sm:flex open-sans text-xl lg:text-2xl
                  font-semibold tracking-tighter text-[#004080] w-52"
                >
                    Brito Consulting
                </h1>
              </Link>
            </div>
            {/* GET STARTED BUTTON */}
            <div className="hidden sm:flex sm:justify-end lg:hidden
            sm:w-full mx-5">
              <Link
                to="/request-meeting"
                className="bg-[#004080] text-white px-5 py-2
                rounded-full font-bold"
                onClick={handleClick}
              >
                  Get Started
              </Link>
            </div>
            {/* NAV LINKS */}
            <div className="h-12 relative">
              <nav className="h-full">
                <div className="flex h-full justify-between items-center">
                  {/* HAMBURGER */}
                  <div className="lg:hidden justify-center flex
                  border-2 border-[#004080] rounded-full p-2 sm:mr-1
                  border-opacity-80 cursor-pointer"
                  onClick={toggleMenu}>
                    <button
                      className="text-[#004080] focus:outline-none
                      h-full text-center"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="#004080"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d={isMenuOpen ? 'M6 18L18 6M6 6l12 12' :
                                  'M4 6h16M4 12h16m-7 6h7'}
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {/* NAVLINKS */}
                  {isMenuOpen && (
                    <>
                      {/* Dropdown Menu */}
                      <ul
                        className={`fixed top-20 left-0 right-0 
                          sm:left-1/2 sm:transform sm:-translate-x-1/2 lg:hidden
                          sm:pl-8 sm:pr-5 sm:pb-2 sm:border-t-[#004080]
                          sm:border-2 px-6 font-semibold sm:rounded-bl-3xl
                          shadow-lg text-lg flex flex-col justify-start
                          tracking-tight bg-white max-h-[70vh] overflow-y-auto
                          z-[50]`}
                      >
                        <div>
                          {navLinks.map((link, index) => (
                            <li key={link.label} className={`text-center 
                            ${index === 0 ? 'mt-2' : 'mt-4'}`}>
                              {link.action ? (
                                        <button
                                          onClick={() => {
                                            scrollToProjects();
                                            setIsMenuOpen(false);
                                          }}
                                          className="py-2 text-black
                                 mx-auto px-4 rounded-full flex
                                 justify-center font-bold opacity-80
                                  tracking-tight cursor-pointer"
                                        >
                                          {link.label}
                                        </button>
                                    ) : (
                                        <Link
                                          to={link.to}
                                          className={`py-2 text-black
                                 mx-auto px-4 rounded-full flex 
                                 justify-center font-bold opacity-80
                                  tracking-tight ${location
                                              .pathname === link.to ?
                                              'bg-[#f3f8ff] ' +
                                                'border-2 border-[#004080]' :
                                              ''}`}
                                          onClick={handleClick}
                                        >
                                          {link.label}
                                        </Link>
                                    )}
                            </li>
                          ))}
                        </div>
                        {/* BUTTON MEETING */}
                        <li className="text-center mt-8 mb-5">
                          <Link
                            to="/request-meeting"
                            className="sm:hidden rounded-full bg-[#004080]
                            w-full px-5 py-2 text-white cursor-pointer
                            font-semibold tracking-tight" onClick={handleClick}
                          >
                                Get Started
                          </Link>
                        </li>
                      </ul>
                    </>
                  )}
                  {/* Desktop Menu */}
                  <ul className="hidden lg:flex gap-5 lg:h-full justify-center
                  flex-grow lg:mr-32">
                    {navLinks.map((link) => (
                      <li key={link.label} className="text-center lg:flex
                      items-center my-2">
                        {link.action ? (
                                <button
                                  onClick={scrollToProjects}
                                  className="block text-[#004080]
                                   lg:font-semibold
                          lg:text-md lg:hover:bg-[#f3f8ff]
                          lg:active:border-[#004080] rounded-full
                          px-5 py-2 cursor-pointer"
                                >
                                  {link.label}
                                </button>
                            ) : (
                                <Link
                                  to={link.to}
                                  className={`block text-[#004080] 
                                  lg:font-semibold
                          lg:text-md lg:hover:bg-[#f3f8ff]
                          lg:active:border-[#004080] rounded-full px-5 py-2 ${
                                        location.pathname === link.to ?
                                            'bg-[#f3f8ff] border-2 ' +
                                            'border-[#004080]' : ''}`}
                                  onClick={handleClick}
                                >
                                  {link.label}
                                </Link>
                            )}
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>
            {/* GET STARTED BUTTON */}
            <div className="hidden lg:flex justify-end">
              <Link
                to="/request-meeting"
                className="bg-[#004080] text-white px-5 py-2
                rounded-full font-bold duration-200 cursor-pointer
                hover:[box-shadow:rgb(183,219,255)_3px_3px]
                hover:bg-blue-700"
              >
                  Get Started
              </Link>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
