import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link as ScrollLink} from 'react-scroll';
import {Link} from 'react-router-dom';
import arrowRight from '../assets/services_page/arrow-right-solid.svg';

const Benefit = ({description, icon}) => (
  <div className="flex flex-col items-center text-center rounded-lg
  bg-white p-6 drop-shadow-sm border border-neutral-250">
    <div className="relative flex h-12 w-12 items-center justify-center
    rounded-lg bg-[#004080] mb-4">
      <FontAwesomeIcon icon={icon} className="text-white text-2xl
      opacity-90" />
    </div>
    <dt className="text-lg font-semibold text-gray-700 mb-1 leading-5
    tracking-tight px-2">
      {description}
    </dt>
  </div>
);

const ProcessStep = ({step, index, length}) => (
  <li key={index} className="flex-start group relative flex
  md:flex-col">
    {index !== length - 1 && (
      <span
        className="grid absolute left-[25px] top-14
        h-[calc(100%_-_37px)]
        w-px bg-[#004080] md:-right-16 md:left-auto md:top-[25px]
        md:h-px md:w-[calc(100%_-_74px)]"
      ></span>
    )}
    <div className="flex justify-center">
      <div
        className="inline-flex h-12 w-12 md:h-14 md:w-14
        items-center justify-center rounded-xl border-2 border-[#004080]"
      >
        <div className="text-[#004080] font-bold text-2xl">
          {step.step}
        </div>
      </div>
    </div>
    <div className="ml-3 md:ml-0 md:mt-3 h-full grid max-md:text-left
    md:text-center">
      <h1 className="text-lg font-semibold text-gray-700 mb-1 leading-5
      tracking-tight px-2 mt-2">
        {step.description}
      </h1>
    </div>
  </li>
);

// const Project = ({title, reverse, image, overview, link}) => (
//   <div className={`my-2 lg:flex h-auto justify-between
//   ${reverse ? 'flex-row-reverse' : ''}`}>
//     {/* IMAGE */}
//     <div className={`${reverse ? 'lg:ml-6 xl:ml-16' : 'lg:mr-6 xl:mr-16'}`}>
//       <img
//         src={image}
//         alt={title}
//         className="lg:w-[500px] rounded-md drop-shadow-md
//         border-neutral-200/50 mb-5 lg:mb-0 lg:h-full"
//       />
//     </div>
//     {/* TEXT SECTION */}
//     <div className="flex flex-col justify-center lg:w-[50%] xl:w-[60%]">
//       <div className="font-poppins text-3xl lg:text-4xl font-bold
//       text-[#2e2e2e] leading-7 tracking-tighter">
//         {title}
//       </div>
//       <div className="w-full my-3">
//         <p className="text-md md:text-lg lg:text-md xl:text-lg
//         tracking-tighter
//         font-normal leading-6 text-gray-500 mb-3 text-left w-full
//         font-open-sans">
//           {overview}
//         </p>
//       </div>
//       <div className="flex items-center">
//         <Link to={link} smooth={true} duration={1000}
//           className="py-3 px-4 flex items-center justify-center gap-3
//           bg-[#004080] text-[#FFFFFF] text-sm
//           lg:text-md font-normal rounded-[8px]
//           hover:bg-blue-700 duration-200 max-w-26 drop-shadow-md
//           border-neutral-200/50 cursor-pointer mr-2"
//           onClick={() => window.scrollTo(0, 0)}>
//           View Project
//           <svg
//             aria-hidden="true"
//             className="text-[#ffffff] w-5 h-5"
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 20 20"
//             fill="currentColor"
//           >
//             <path
//               d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15
//               6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
//             ></path>
//             <path
//               d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2
//               0v3H5V7h3a1 1 0 000-2H5z"
//             ></path>
//           </svg>
//         </Link>
//       </div>
//     </div>
//   </div>
// );

const LearnMoreService = ({
  title, image, description, keyBenefits = [], process = [], portfolioData = [],
}) => {
  return (
    <section className="bg-slate-50">
      {/* HERO SECTION */}
      <section className='bg-white relative overflow-hidden'>
        <section className="grid md:flex flex-col md:flex-row w-full max-w-7xl
        px-4 md:px-8 pt-12 md:py-20 lg:pt-28 pb-20 md:pb-5 lg:pb-20 mx-auto
        items-center">
          <div className="flex flex-col justify-between gap-5 md:w-1/2
          md:mr-10">
            <div className="font-poppins text-4xl sm:text-5xl lg:text-6xl
            font-bold text-[#000000] tracking-tighter lg:mb-0
            md:text-left pt-10 text-balance">
              {title}
            </div>
            <div className="text-xl md:text-lg xl:text-xl
            tracking-tight font-normal leading-6 text-gray-500
            md:text-left w-full mb-2">
              <p>{description}</p>
            </div>
            <div className="grid sm:flex w-full gap-3">
              <ScrollLink to="learn-more-section"
                smooth={true}
                duration={1000}
                className="py-3 px-4 flex items-center justify-center gap-3
                bg-[#004080] text-[#FFFFFF] text-sm
                lg:text-md font-semibold rounded-full
                hover:bg-blue-700 duration-200 max-w-26 drop-shadow-md
                border-neutral-200/50 cursor-pointer sm:mr-3
                hover:[box-shadow:rgb(183,219,255)_3px_3px]"
                onClick={() => window.scrollTo(0, 0)}>
                Explore Service
                <img src={arrowRight} alt="Arrow Right" className="h-4
                hidden md:flex" />
              </ScrollLink>
              <ScrollLink to="schedule-free-meeting"
                smooth={true}
                duration={1000}
                className="py-3 px-4 flex items-center justify-center gap-3
                text-sm font-semibold rounded-full border hover:text-blue-700
                cursor-pointer border-slate-300 hover:bg-slate-100"
                onClick={() => window.scrollTo(0, 0)}>
                Schedule a Free Consultation
              </ScrollLink>
            </div>
          </div>
          <div className="relative w-full md:w-1/2 lg:w-1/2 md:h-80 h-32 sm:h-64
          mt-5 md:mt-0 md:-bottom-28 lg:-bottom-0 lg:flex">
            <img src={image} alt={title}
              className="absolute w-full rounded-t-xl"/>
          </div>
        </section>
      </section>
      {/* BODY */}
      <section id="learn-more-section"
        className="w-full max-w-7xl px-4 lg:px-8 my-5 mx-auto
        pt-8 md:pt-20">
        {/* EXPLORE SERVICES */}
        <section className="w-full text-center px-2 sm:px-12 lg:px-32 lg:mb-20
        md:mb-12 mb-8">
          <h2 className='font-poppins text-3xl md:text-4xl
          font-bold text-[#333333] mb-5 tracking-tight'>Explore Service</h2>
          <p className="font-open-sans text-xl leading-6 text-gray-500
          tracking-tight">
            {description}
          </p>
        </section>
        {/* KEY FEATURES */}
        <section className='mb-20'>
          <div className="w-full mx-auto flex justify-center">
            <h2 className="text-base font-semibold leading-7 text-blue-100
            bg-[#004080] px-3 rounded-lg uppercase mb-6 lg:mb-8
            text-center ">Key Features</h2>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-7'>
            {keyBenefits.map((benefit, index) => (
              <Benefit key={index}
                description={benefit.description}
                icon={benefit.icon} />
            ))}
          </div>
        </section>
        {/* PROCESS */}
        <section className='mb-20'>
          <div className="w-full mx-auto flex justify-center">
            <h2 className="text-base font-semibold leading-7 text-blue-100
              bg-[#004080] px-3 rounded-lg uppercase mb-5
              text-center">Our Development Process</h2>
          </div>
          <ul className="mx-auto mt-5 grid max-w-md grid-cols-1 gap-6
          md:gap-2 sm:mt-16 md:mt-12 md:max-w-7xl md:grid-cols-6
          max-md:pl-14">
            {process.map((step, index) => (
              <ProcessStep key={index} step={step}
                index={index} length={process.length} />
            ))}
          </ul>
        </section>
        {/* PORTFOLIO */}
        {/* <section className=''>
          <div className="mx-auto flex flex-col w-auto justify-center">
            <h2 className="text-base font-semibold leading-7 text-blue-100
              bg-[#004080] px-3 rounded-lg uppercase mb-5 mx-auto
              text-center">Our Portfolio
            </h2>
            <div className="flex flex-col sm:grid-cols-2 md:grid-cols-4 gap-7">
              {portfolioData.map((project, index) => (
                <Project
                  key={index}
                  title={project.title}
                  overview={project.overview}
                  image={project.image}
                  link={project.link}
                  reverse={project.reverse}
                />
              ))}
            </div>
          </div>
        </section> */}
      </section>
      {/* REQ. MEETING */}
      <section id='schedule-free-meeting' className="">
        <section className='pb-20 w-full max-w-7xl px-4 lg:px-8 my-5 mx-auto
        pt-8 md:pt-20'>
          <div className="mx-auto max-w-7xl">
            <div className="bg-[#004080] rounded-2xl p-8 xl:p-11
            shadow-md shadow-gray-200">
              <div className="text-center">
                <h2 className="mt-6 text-3xl font-bold tracking-tight
                sm:text-4xl lg:text-5xl text-[#ffffff]">
                  Ready to Get Started?
                </h2>
              </div>
              <p className="text-indigo-200 text-center my-8 max-lg:max-w-2xl
              mx-auto text-xl px-0 lg:px-36 font-open-sans leading-6
              tracking-tight">
                  Experience unparalleled quality and innovation with our
                  bespoke solutions tailored to meet your specific needs.
                  Elevate your business to new heights with our expertise.
              </p>
              <div className="flex justify-center">
                <Link to="/request-meeting"
                  className="bg-[#ffffff] text-[#000000] px-7 py-4 rounded-full
                  text-base font-bold flex items-center justify-center
                  tracking-tight hover:text-blue-700"
                  onClick={() => window.scrollTo(0, 0)}
                >
                      Schedule a Free Consultation
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default LearnMoreService;
