import britoConsultingLogo from
  '../assets/home_page/brito-consulting-logo3.png';
import facebookIcon from '../assets/home_page/footer/facebook.png';
import instagramIcon from '../assets/home_page/footer/instagram.png';
import linkedinIcon from '../assets/home_page/footer/linkedin.png';
import xIcon from '../assets/home_page/footer/x-twitter.png';
import youtubeIcon from '../assets/home_page/footer/youtube.png';
import {COMPANY_ADDRESS, CONTACT_EMAIL} from '../config';

const Footer = () => {
  return (
    <footer>
      <div className="bg-gray-800 p-5">
        <div className="max-w-screen-lg px-4 sm:px-6 text-gray-400
        sm:grid md:grid-cols-4 sm:grid-cols-2 mx-auto">
          <div className="py-5 px-1 flex-col items-center sm:items-start
            hidden sm:flex">
            <img src={britoConsultingLogo} alt="Brito Consulting Logo"
              className="w-[auto] h-[120px]" />
          </div>
          <div className="py-5 px-1">
            <div className="text-sm uppercase text-gray-300 font-bold">
              Resources
            </div>
            <div className="my-2 block w-auto">
              <a className='hover:text-blue-400' href="/services">Services</a>
            </div>
            <div className="my-2 block w-auto">
              <a className='hover:text-blue-400' href="/portfolio">Portfolio</a>
            </div>
            <div
              className="my-2 block">
              <a className='hover:text-blue-400' href="/about">About Us</a>
            </div>
          </div>
          <div className="py-5 px-1">
            <div className="text-sm uppercase text-gray-300 font-bold">
              Suppport
            </div>
            <div className="my-2 block w-auto">
              <a className='hover:text-blue-400' href="/contact">Contact Us</a>
            </div>
            <div className="my-2 block w-auto">
              <a className='hover:text-blue-400' href="/news">News</a>
            </div>
            <div
              className="my-2 block">
              <a className='hover:text-blue-400' href="/#">Documentation</a>
            </div>
          </div>
          <div className="py-5 px-1">
            <div className="text-sm uppercase text-gray-300 font-bold">
              Contact us
            </div>
            <div className="my-3 block text-left w-full">
              {COMPANY_ADDRESS}
            </div>
            <div className="my-1 md:hidden lg:flex
              hover:text-blue-400 hover:cursor-pointer">
              <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 pt-2">
        <div className="flex pb-5 px-3 m-auto pt-2
      text-gray-800 text-sm flex-col max-w-screen-lg items-center">
          <div className="mt-2 flex items-center">
            <a href="/#" className="w-5 mx-1 opacity-20 hover:opacity-100
            duration-100">
              <img src={facebookIcon} alt="facebookIcon" className="" />
            </a>
            <a href="/#" className="w-5 mx-1 opacity-20 hover:opacity-100
            duration-100">
              <img src={instagramIcon} alt="instagramIcon" className="" />
            </a>
            <a href="/#" className="w-5 mx-1 opacity-20 hover:opacity-100
            duration-100">
              <img src={linkedinIcon} alt="linkedinIcon" className="" />
            </a>
            <a href="/#" className="w-6 mx-1 opacity-20 hover:opacity-100
            duration-100">
              <img src={xIcon} alt="xIcon" className="" />
            </a>
            <a href="/#" className="w-6 mx-1 opacity-20 hover:opacity-100
            duration-100">
              <img src={youtubeIcon} alt="youtubeIcon" className="" />
            </a>
          </div>
          <div className="mt-5 mb-2 text-white opacity-20">
            © Copyright 2024. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
