import HeroSection from '../components/tiago_page/HeroSection';
import PersonalIntroduction from
  '../components/tiago_page/PersonalIntroduction';
import JourneyTimeline from '../components/tiago_page/JourneyTimeline';
import BritoConsulting from '../components/tiago_page/BritoConsulting';
import VisionAndGoals from '../components/tiago_page/VisionAndGoals';
import InterestsAndPassions
  from '../components/tiago_page/InterestsAndPassions';
import CallToAction from '../components/tiago_page/CallToAction';
import ProjectsSection from '../components/tiago_page/ProjectsSection';

const Tiago = () => {
  return (
    <main>
      <HeroSection />
      <PersonalIntroduction />
      <ProjectsSection />
      <JourneyTimeline />
      <BritoConsulting />
      <VisionAndGoals />
      <InterestsAndPassions />
      <CallToAction />
    </main>
  );
};

export default Tiago;
