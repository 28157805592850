import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [careerApplications, setCareerApplications] = useState([]);
  const [meetingRequests, setMeetingRequests] = useState([]);
  const [contactSections, setContactSections] = useState([]);
  const [errors, setErrors] = useState({});

  const fetchData = async (endpoint, setData) => {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });

      if (response.ok) {
        const data = await response.json();
        setData(data);
      } else {
        const errorData = await response.json();
        setErrors({form: errorData.message || 'Failed to fetch data.'});
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrors({
        form: error.message.includes('Failed to fetch') ?
                    'The server is currently down. Please try again later.' :
                    'An unexpected error occurred. Please try again later.',
      });
    }
  };

  useEffect(() => {
    fetchData('contact', setContacts);
    fetchData('career', setCareerApplications);
    fetchData('meeting-request', setMeetingRequests);
    fetchData('contact-section', setContactSections);
  }, []);

  const handleDelete = async (endpoint, id, setData) => {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/${endpoint}/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });

      if (response.ok) {
        setData((prevData) => prevData.filter((item) => item._id !== id));
      } else {
        const errorData = await response.json();
        setErrors({form: errorData.message || 'Failed to delete data.'});
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      setErrors({
        form: error.message.includes('Failed to fetch') ?
                    'The server is currently down. Please try again later.' :
                    'An unexpected error occurred. Please try again later.',
      });
    }
  };

  const handleEdit = (endpoint, id) => {
    navigate(`/admin/${endpoint}/${id}/edit`);
  };

  const handleDownload = async (filename) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrors({form: 'Not authorized, no token'});
      return;
    }

    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/career/download/${filename}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        const errorData = await response.json();
        setErrors({form: errorData.message || 'Failed to download CV.'});
      }
    } catch (error) {
      console.error('Error downloading CV:', error);
      setErrors({
        form: error.message.includes('Failed to fetch') ?
                    'The server is currently down. Please try again later.' :
                    'An unexpected error occurred. Please try again later.',
      });
    }
  };

  const formatDate = (dateString) => {
    const options = {year: 'numeric', month: 'long',
      day: 'numeric', hour: 'numeric', minute: 'numeric'};
    return new Date(dateString).toLocaleString('en-US', options);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin/login');
  };

  return (
    <div className="flex flex-col items-center pt-32 px-5 mb-8">
      <h1 className="font-poppins text-3xl font-bold text-[#333333] mb-8">
                Admin Dashboard
      </h1>
      <button
        className="bg-[#004080] text-white px-4 py-2 rounded-[8px]
        font-poppins text-base font-bold hover:bg-[#003366] mb-8"
        onClick={handleLogout}
      >
                Log Out
      </button>
      {errors.form && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700
           p-4 mb-8 w-full max-w-4xl"
          role="alert"
        >
          <p className="font-bold">Error</p>
          <p>{errors.form}</p>
        </div>
      )}
      <div className="max-w-6xl w-full">
        <h2 className="font-poppins text-2xl font-bold text-[#333333]
        mb-5">Contacts</h2>
        {contacts.map((contact) => (
          <div key={contact._id} className="bg-[#E0E0E0] p-4 mb-4
          rounded-lg shadow-md">
            <p className="font-open-sans text-lg text-[#404040]">
                Name: {contact.fullName}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Email: {contact.email}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Phone: {contact.countryCode} {contact.phone}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Message: {contact.message}</p>
            <p className="font-open-sans text-sm text-[#606060]">
                Sent on: {formatDate(contact.createdAt)}</p>
            <div className="flex space-x-4 mt-2">
              <button
                className="bg-[#004080] text-white px-4 py-2 rounded-[8px]
                font-poppins text-base font-bold hover:bg-[#003366]"
                onClick={() => handleEdit('contact', contact._id)}
              >
                                Edit
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-[8px]
                font-poppins text-base font-bold hover:bg-red-700"
                onClick={() => handleDelete(
                    'contact', contact._id, setContacts)}
              >
                                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="max-w-6xl w-full">
        <h2 className="font-poppins text-2xl font-bold text-[#333333] mb-5">
            Career Applications</h2>
        {careerApplications.map((application) => (
          <div key={application._id} className="bg-[#E0E0E0] p-4 mb-4
           rounded-lg shadow-md">
            <p className="font-open-sans text-lg text-[#404040]">
                Name: {application.fullName}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Email: {application.email}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Phone: {application.countryCode} {application.phone}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Why Join: {application.whyJoin}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                What Bring: {application.whatBring}</p>
            <p className="font-open-sans text-sm text-[#606060]">
                Sent on: {formatDate(application.createdAt)}</p>
            <div className="flex space-x-4 mt-2">
              <button
                className="bg-[#004080] text-white px-4 py-2 rounded-[8px]
                 font-poppins text-base font-bold hover:bg-[#003366]"
                onClick={() => handleEdit('career', application._id)}
              >
                                Edit
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-[8px]
                 font-poppins text-base font-bold hover:bg-red-700"
                onClick={() => handleDelete(
                    'career', application._id, setCareerApplications)}
              >
                                Delete
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-[8px]
                 font-poppins text-base font-bold hover:bg-green-700"
                onClick={() => handleDownload(application.cvPath)}
              >
                                Download CV
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="max-w-6xl w-full">
        <h2 className="font-poppins text-2xl font-bold text-[#333333] mb-5">
            Meeting Requests</h2>
        {meetingRequests.map((request) => (
          <div key={request._id} className="bg-[#E0E0E0] p-4 mb-4
          rounded-lg shadow-md">
            <p className="font-open-sans text-lg text-[#404040]">
                Name: {request.fullName}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Email: {request.email}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Phone: {request.countryCode} {request.phone}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Company: {request.companyName}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Project Details: {request.projectDetails}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Preferred Date: {formatDate(request.preferredDate)}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Available From: {request.availableFrom}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Available To: {request.availableTo}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Time Zone: {request.timeZone}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Meeting Type: {request.meetingType}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Meeting Place: {request.meetingPlace}</p>
            <p className="font-open-sans text-sm text-[#606060]">
                Sent on: {formatDate(request.createdAt)}</p>
            <div className="flex space-x-4 mt-2">
              <button
                className="bg-[#004080] text-white px-4 py-2
                rounded-[8px] font-poppins text-base font-bold
                 hover:bg-[#003366]"
                onClick={() => handleEdit('meeting-request', request._id)}
              >
                                Edit
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-[8px]
                 font-poppins text-base font-bold hover:bg-red-700"
                onClick={() => handleDelete(
                    'meeting-request', request._id, setMeetingRequests)}
              >
                                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="max-w-6xl w-full">
        <h2 className="font-poppins text-2xl font-bold text-[#333333] mb-5">
            Contact Sections</h2>
        {contactSections.map((section) => (
          <div key={section._id} className="bg-[#E0E0E0] p-4 mb-4
          rounded-lg shadow-md">
            <p className="font-open-sans text-lg text-[#404040]">
                Name: {section.fullName}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Email: {section.email}</p>
            <p className="font-open-sans text-lg text-[#404040]">
                Message: {section.message}</p>
            <p className="font-open-sans text-sm text-[#606060]">
                Sent on: {formatDate(section.createdAt)}</p>
            <div className="flex space-x-4 mt-2">
              <button
                className="bg-[#004080] text-white px-4 py-2
                rounded-[8px] font-poppins text-base font-bold
                hover:bg-[#003366]"
                onClick={() => handleEdit('contact-section', section._id)}
              >
                                Edit
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-[8px]
                 font-poppins text-base font-bold hover:bg-red-700"
                onClick={() => handleDelete(
                    'contact-section', section._id, setContactSections)}
              >
                                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
