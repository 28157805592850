import React from 'react';
import ReceivedPage from '../components/ReceivedPage';
import meetingImage from '../assets/home_page/inquiry_received.webp';

const MeetingRequestReceived = () => {
  return (
    <ReceivedPage
      title="Consultation Request Received"
      message="Thank you for reaching out to Brito Consulting.
       We have received your request for a free consultation.
       Our team will get back to you shortly with available meeting times."
      image={meetingImage}
      buttonText="Back to Home"
      buttonLink="/"
    />
  );
};

export default MeetingRequestReceived;
