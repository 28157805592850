import React from 'react';
import ReceivedPage from '../components/ReceivedPage';
import inquiryImage from '../assets/home_page/inquiry_received.webp';

const InquiryReceived = () => {
  return (
    <ReceivedPage
      title="Inquiry Received"
      message="Thank you for getting in touch with Brito Consulting.
      We have received your inquiry and will respond to you as
       soon as possible."
      image={inquiryImage}
      buttonText="Back to Home"
      buttonLink="/"
    />
  );
};

export default InquiryReceived;
