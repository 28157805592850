import React from 'react';
import imageCanada from '../../../assets/tiago_page/canada.jpg';

const CanadaMilestone = () => {
  const paragraph1 = (
    <>
        After my enriching experiences in Norway
        and the success in various university
        projects and hackathons, I felt ready to
        take on a new challenge. The next pivotal
        moment came when I decided to attend Collision
        Conference 2024 in Toronto, Canada—a decision
        that would change the course of my life. Originally,
        I had planned to return to Norway after the
        conference to continue working as a teaching
        assistant during the summer, but the possibility
        of starting my own business in Canada during the
        summer was too compelling to pass up.
    </>
  );

  const paragraph2 = (
    <>
        Starting my business in North America was an
        opportunity I couldn’t ignore. I quickly acted
        on this decision, applying for a work visa
        through the International Experience Canada
        program, which allows young people from 18 to
        35 to work in Canada. To complete the process,
        I traveled to Stockholm, Sweden, to have my
        fingerprints taken at a Canadian VAC center. Once
        my visa was approved, I informed my employer
        in Norway of my plans and found a place to stay in Toronto.
    </>
  );

  const paragraph3 = (
    <>
            Fast forward a month, and I had already
            fallen in love with Toronto. The city&apos;s
            towering skyscrapers and vibrant tech scene
            were unlike anything I had ever experienced.
            The energy of the city fueled my ambition and
            solidified my decision to move there and
            build something meaningful.
    </>
  );

  const paragraph4 = (
    <>
            The transition wasn’t easy, but it was
            worth every challenge. Moving to a new
            country, navigating the visa process, and
            adjusting to a new city while starting a
            business was daunting. However, I was
            fortunate to make great friends during the
            Collision Conference who played a crucial
            role in my journey. Their unwavering support
            and guidance provided me with the strength
            and confidence needed to navigate the complexities
            of starting a business in a new environment. Their
            insights and connections were instrumental in
            helping me overcome obstacles, shaping my resilience
            and adaptability—qualities that have become
            the backbone of my entrepreneurial journey.
    </>
  );

  return (
    <section className="py-10 md:py-20 bg-gray-50 text-gray-800 px-8">
      <div className="container mx-auto">
        <img src={imageCanada} alt="Canada Flag"
          className="w-full h-64 md:h-80
                     object-cover rounded-lg mb-8"/>
        <h2 className="text-3xl md:text-4xl
                font-bold mb-6">Moving to Canada</h2>
        <p className="text-lg md:text-xl
                leading-relaxed">{paragraph1}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph2}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph3}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph4}</p>
      </div>
    </section>
  );
};

export default CanadaMilestone;
