import React from 'react';
import projectImage
  from '../../../assets/tiago_page/projects_section/florence.jpg';

const CodeDaVinci = () => {
  const paragraph1 = (
    <>
        Code Da Vinci is a groundbreaking platform that utilizes
        artificial intelligence to revolutionize digital product
        development. Users can interact directly with an AI—whether
        through text or voice—to define their project vision. Based
        on these inputs, the AI generates customizable template
        designs similar to Figma. Once the user selects or modifies
        the design, the AI autonomously implements it, writes the
        code on GitHub, enables real-time testing, handles deployment,
        and can even continue to improve and develop existing
        projects in connected GitHub repositories, creating a
        seamless and efficient end-to-end development experience.
    </>
  );

  const paragraph2 = (
    <>
        This streamlined workflow eliminates the need for
        advanced coding skills, accelerates project timelines,
        and offers unparalleled flexibility. Key features
        include AI-driven project creation, automated design
        generation, seamless GitHub integration for code
        deployment, real-time testing, autonomous deployment,
        and ongoing development support for existing projects.
        Code Da Vinci empowers businesses to significantly
        reduce development costs and complexity, allowing
        users to focus on innovation while the AI manages
        all technical aspects.
    </>
  );

  const paragraph3 = (
    <>
        By adopting Code Da Vinci, businesses can significantly
        reduce the time, cost, and complexity of digital product
        development, empowering users to focus on innovation
        while the AI continuously manages and improves the
        technical execution.
    </>
  );

  return (
    <section id="code-da-vinci" className="bg-white text-gray-800">
      {/* Full-width Image */}
      <img src={projectImage} alt="Code Da Vinci"
        className="w-full h-64 md:h-96
                     object-cover object-bottom rounded-lg mb-8"/>

      {/* Content Section */}
      <div className="container mx-auto py-12 px-8">
        <h1 className="text-4xl font-bold text-center mb-8">
                    Code Da Vinci
        </h1>
        <div className="text-left">
          <p className="text-lg mb-6">{paragraph1}</p>
          <p className="text-lg mb-6">{paragraph2}</p>
          <p className="text-lg mb-6">Key features of Code Da Vinci include:</p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>AI-driven project creation through text or voice
                interaction.</li>
            <li>Automated generation of customizable design templates.</li>
            <li>Seamless code creation, deployment, and ongoing
                development on GitHub.</li>
            <li>Real-time testing and automated deployment.</li>
            <li>Support for websites, mobile apps, and software.</li>
          </ul>
          <p className="text-lg mb-6">{paragraph3}</p>
        </div>
      </div>
    </section>
  );
};

export default CodeDaVinci;
