import React from 'react';
import ReceivedPage from '../components/ReceivedPage';
import applicationImage from '../assets/home_page/inquiry_received.webp';

const ApplicationReceived = () => {
  return (
    <ReceivedPage
      title="Application Received"
      message="Thank you for applying to join Brito Consulting.
             We have received your application and will review it
              carefully. Our team will get back to you soon with
              the next steps."
      image={applicationImage}
      buttonText="Back to Home"
      buttonLink="/"
    />
  );
};

export default ApplicationReceived;
