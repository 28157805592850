import React from 'react';
import imageOslo from '../../../assets/tiago_page/oslo.jpg';

const NorwayMilestone = () => {
  const paragraph1 = (
    <>
        When I moved to Norway, I was introduced to
        a new world full of opportunities. The education
        system in Norway is renowned for its emphasis
        on fostering independence and critical thinking,
        offering students the freedom to explore their
        interests. This environment was a stark contrast
        to what I had experienced in Portugal, where
        education was often more rigid and constraining.
        In Norway, this focus allowed me to thrive both
        academically and personally.
    </>
  );

  const paragraph2 = (
    <>
        Norway’s history is rich with stories of the
        Vikings—fierce warriors, skilled navigators,
        and ambitious traders who were known for their
        expeditions across the seas. The Vikings weren’t
        just conquerors; they were also explorers and
        merchants who connected different parts of the
        world through trade. Living in Norway, I couldn’t
        help but feel a connection to this heritage. The
        spirit of exploration that characterized the
        Vikings complemented my Portuguese DNA, making
        my drive to explore the unknown unstoppable.
    </>
  );

  const paragraph3 = (
    <>
            As I immersed myself in my studies, I found
            that the modern Norwegian work culture, with
            its emphasis on professionalism, quality, and
            a balanced approach to life, profoundly
            influenced my approach to everything I did. In
            Norway, there is a strong focus on doing things
            the right way, with precision, efficiency, and
            a commitment to excellence. This cultural
            mindset shaped my work ethic, driving me to
            achieve the highest standards in my work.
    </>
  );

  const paragraph4 = (
    <>
            Norway also deepened my love for travel. The
            stunning landscapes and the freedom to explore
            them reinforced my desire to see the world
            and experience new cultures. The Norwegian
            way of life, which balances work and leisure,
            inspired me to pursue a career that would allow
            me to combine my passion for travel with my
            professional ambitions. With the controlled
            energy I gained from my physical discipline,
            I was able to focus on my goals with even
            greater intensity, setting the stage for my
            future success as an entrepreneur.
    </>
  );
  return (
    <section className="py-10 md:py-20 bg-gray-50 text-gray-800 px-8">
      <div className="container mx-auto">
        <img src={imageOslo} alt="Oslo" className="w-full
         h-64 md:h-96 object-cover rounded-lg mb-8"/>
        <h2 className="text-3xl md:text-4xl font-bold
        mb-6">Educational Journey in Norway</h2>
        <p className="text-lg md:text-xl leading-relaxed">{paragraph1}</p>
        <p className="text-lg md:text-xl leading-relaxed mt-4">{paragraph2}</p>
        <p className="text-lg md:text-xl leading-relaxed mt-4">{paragraph3}</p>
        <p className="text-lg md:text-xl leading-relaxed mt-4">{paragraph4}</p>
      </div>
    </section>
  );
};

export default NorwayMilestone;
