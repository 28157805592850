import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import countryCodes from '../assets/phone-number-length-by-country-2024.json';
import careersImage from '../assets/careers/image1.webp';

const Careers = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    countryCode: '',
    whyJoin: '',
    whatBring: '',
    uploadCV: null,
  });

  const [errors, setErrors] = useState({});
  const fullNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const countryCodeRef = useRef(null);
  const whyJoinRef = useRef(null);
  const whatBringRef = useRef(null);
  const uploadCVRef = useRef(null);

  const sortedCountryCodes = countryCodes.sort((a, b) =>
    a.country.localeCompare(b.country));

  const handleChange = (e) => {
    const {id, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: '',
    }));
  };

  const handleFileChange = (e) => {
    const {id, files} = e.target;
    const file = files[0];

    if (file && file.type !== 'application/pdf') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: 'Only PDF files are allowed.',
      }));
      setFormData((prevData) => ({
        ...prevData,
        [id]: null,
      }));
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [id]: file,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: '',
    }));
  };

  const restrictInput = (e, pattern) => {
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 110,
        behavior: 'smooth',
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const errorRefs = [];

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full Name is required';
      errorRefs.push(fullNameRef);
    } else if (formData.fullName.length < 2 || formData.fullName.length > 100) {
      newErrors.fullName = 'Full Name must be between 2 and 100 characters.';
      errorRefs.push(fullNameRef);
    } else if (!/^[a-zA-ZÀ-ÿ\s'-]+$/.test(formData.fullName)) {
      newErrors.fullName = 'Full Name can only contain alphabetic' +
          ' characters, spaces, and hyphens.';
      errorRefs.push(fullNameRef);
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
      errorRefs.push(emailRef);
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
      errorRefs.push(emailRef);
    } else if (formData.email.length > 254) {
      newErrors.email = 'Email address is too long';
      errorRefs.push(emailRef);
    }

    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
      errorRefs.push(phoneRef);
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is invalid';
      errorRefs.push(phoneRef);
    } else {
      const selectedCountryCode = formData.countryCode.split(' ')[0];
      const selectedCountry = sortedCountryCodes.find(
          (c) => `+${c.phoneNumberLengthByCountry_CountryCode}` ===
              selectedCountryCode,
      );

      if (selectedCountry) {
        if (
          selectedCountry.phoneNumberLengthByCountry_phLengthMin ===
            selectedCountry.phoneNumberLengthByCountry_phLengthMax
        ) {
          if (formData.phone.length !== selectedCountry
              .phoneNumberLengthByCountry_phLengthMin) {
            newErrors.phone = `Phone number must be ${selectedCountry
                .phoneNumberLengthByCountry_phLengthMin} digits long.`;
            errorRefs.push(phoneRef);
          }
        } else if (
          formData.phone.length < selectedCountry
              .phoneNumberLengthByCountry_phLengthMin ||
            formData.phone.length > selectedCountry
                .phoneNumberLengthByCountry_phLengthMax
        ) {
          newErrors.phone = `Phone number must be between ${selectedCountry
              .phoneNumberLengthByCountry_phLengthMin} and ${selectedCountry
              .phoneNumberLengthByCountry_phLengthMax} digits long.`;
          errorRefs.push(phoneRef);
        }
      }
    }

    if (!formData.countryCode) {
      newErrors.countryCode = 'Country Code is required';
      errorRefs.push(countryCodeRef);
    }

    if (!formData.whyJoin.trim()) {
      newErrors.whyJoin = 'Reason for joining is required';
      errorRefs.push(whyJoinRef);
    } else if (formData.whyJoin.length < 20 ||
        formData.whyJoin.length > 1000) {
      newErrors.whyJoin = 'Reason for joining must be' +
          ' between 20 and 1000 characters.';
      errorRefs.push(whyJoinRef);
    }

    if (!formData.whatBring.trim()) {
      newErrors.whatBring = 'Contribution details are required';
      errorRefs.push(whatBringRef);
    } else if (formData.whatBring.length < 20 ||
        formData.whatBring.length > 1000) {
      newErrors.whatBring = 'Contribution details must be' +
          ' between 20 and 1000 characters.';
      errorRefs.push(whatBringRef);
    }

    if (!formData.uploadCV) {
      newErrors.uploadCV = 'CV upload is required';
      errorRefs.push(uploadCVRef);
    }

    setErrors(newErrors);

    if (errorRefs.length > 0) {
      scrollToRef(errorRefs[0]);
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });

    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/career`, {
            method: 'POST',
            body: form,
          });
      if (response.ok) {
        navigate('/application-received');
        window.scrollTo(0, 0);
      } else if (response.status === 413) {
        setErrors({
          form: 'The file size is too large. Please upload a ' +
              'file smaller than 5 MB.',
        });
      } else {
        const errorData = await response.json();
        setErrors({
          form: errorData.message || 'Failed to submit application.',
        });
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      setErrors({
        form: error.message.includes('Failed to fetch') ?
            'The server is currently down. Please try again later.' :
            'An unexpected error occurred. Please try again later.',
      });
    }
  };

  return (
    <div className="flex flex-col items-center pt-32 px-5 mb-8">
      <h1 className="font-poppins text-3xl font-bold text-[#333333] mb-8">
          Careers at Brito Consulting
      </h1>
      <div className="flex justify-center mb-8">
        <img src={careersImage} alt="Careers at Brito Consulting"
          className="w-[800px] h-[450px]" />
      </div>
      <section className="mb-8 max-w-4xl w-full">
        <h2 className="font-poppins text-2xl font-bold text-[#333333]
        mb-5">Why Join Us?</h2>
        <p className="font-open-sans text-lg text-[#404040]">
            At Brito Consulting, we believe in fostering
            a collaborative and innovative work
            environment. Our team is dedicated to pushing
            the boundaries of technology and delivering
            exceptional solutions to our clients. Join us
            to be part of a dynamic team that values
            creativity, excellence, and professional growth.
        </p>
      </section>
      <section className="mb-8 max-w-4xl w-full">
        <h2 className="font-poppins text-2xl font-bold text-[#333333]
        mb-5">Submit Your Interest</h2>
        <form className="max-w-3xl w-full" onSubmit={handleSubmit} noValidate>
          <div className="mb-4">
            <label htmlFor="fullName" className="font-open-sans text-lg
             text-[#404040] block mb-2">
                Full Name
            </label>
            <input
              id="fullName"
              type="text"
              className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
               border border-[#CCCCCC] px-2"
              value={formData.fullName}
              onChange={handleChange}
              ref={fullNameRef}
              onKeyPress={(e) => restrictInput(e, /^[a-zA-ZÀ-ÿ\s'-]$/)}
            />
            {errors.fullName && <p className="text-red-500">
              {errors.fullName}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="font-open-sans text-lg
             text-[#404040] block mb-2">
                Email Address
            </label>
            <input
              id="email"
              type="email"
              className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
              border border-[#CCCCCC] px-2"
              value={formData.email}
              onChange={handleChange}
              ref={emailRef}
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>
          <div className="flex flex-row mb-4">
            <div className="flex flex-col w-1/3 mr-2">
              <label htmlFor="countryCode" className="font-open-sans
               text-lg text-[#404040] block mb-2">
                  Country Code
              </label>
              <select
                id="countryCode"
                className="bg-[#E0E0E0] h-[40px] rounded-[5px]
                border border-[#CCCCCC] px-2 downward-dropdown"
                value={formData.countryCode}
                onChange={handleChange}
                ref={countryCodeRef}
              >
                <option value="">Select Country Code</option>
                {sortedCountryCodes.map((country) => (
                  <option
                    key={`${country.phoneNumberLengthByCountry_CountryCode}
                    -${country.country}`}
                    value={`+${country.phoneNumberLengthByCountry_CountryCode} 
                    ${country.country}`}
                  >
                    {country.country} (+{country
                        .phoneNumberLengthByCountry_CountryCode})
                  </option>
                ))}
              </select>
              {errors.countryCode && <p className="text-red-500">
                {errors.countryCode}</p>}
            </div>
            <div className="flex flex-col w-2/3">
              <label htmlFor="phone" className="font-open-sans text-lg
               text-[#404040] block mb-2">
                  Phone Number
              </label>
              <input
                id="phone"
                type="text"
                className="bg-[#E0E0E0] w-full h-[40px] rounded-[5px]
                 border border-[#CCCCCC] px-2"
                value={formData.phone}
                onChange={handleChange}
                ref={phoneRef}
                onKeyPress={(e) => restrictInput(e, /^\d$/)}
              />
              {errors.phone && <p className="text-red-500">{errors.phone}</p>}
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="whyJoin" className="font-open-sans text-lg
             text-[#404040] block mb-2">
                Why You Want to Join Brito Consulting
            </label>
            <textarea
              id="whyJoin"
              className="bg-[#E0E0E0] w-full h-[120px] rounded-[5px]
              border border-[#CCCCCC] px-2 py-1 resize-none"
              value={formData.whyJoin}
              onChange={handleChange}
              ref={whyJoinRef}
            ></textarea>
            {errors.whyJoin && <p className="text-red-500">{errors.whyJoin}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="whatBring" className="font-open-sans text-lg
             text-[#404040] block mb-2">
                What You Can Bring to the Team
            </label>
            <textarea
              id="whatBring"
              className="bg-[#E0E0E0] w-full h-[120px] rounded-[5px]
              border border-[#CCCCCC] px-2 py-1 resize-none"
              value={formData.whatBring}
              onChange={handleChange}
              ref={whatBringRef}
            ></textarea>
            {errors.whatBring && <p className="text-red-500">
              {errors.whatBring}</p>}
          </div>
          <div className="mb-8">
            <label htmlFor="uploadCV" className="font-open-sans text-lg
             text-[#404040] block mb-2">
                Upload Your CV
            </label>
            <input
              id="uploadCV"
              type="file"
              className="bg-[#E0E0E0] w-full rounded-[5px] border
              border-[#CCCCCC] px-2 py-1"
              onChange={handleFileChange}
              ref={uploadCVRef}
              accept=".pdf"
            />
            {errors.uploadCV && <p className="text-red-500">
              {errors.uploadCV}</p>}
          </div>
          <button
            type="submit"
            className="bg-[#004080] text-white w-[200px] h-[50px]
            rounded-[8px] font-poppins text-base font-bold
            hover:bg-[#003366] flex items-center justify-center"
          >
              Submit Application
          </button>
        </form>
      </section>
      {errors.form && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700
           p-4 w-full max-w-4xl"
          role="alert"
        >
          <p className="font-bold">Error</p>
          <p>{errors.form}</p>
        </div>
      )}
    </div>
  );
};

export default Careers;
