import React from 'react';
import projectImage
  from '../../../assets/tiago_page/projects_section/chatgpt.jpg';

const LulAI = () => {
  const paragraph1 = (
    <>
        LulAI is a groundbreaking AI-driven platform
        designed to transform the in-store shopping
        experience by providing personalized recommendations
        and real-time assistance. Leveraging advanced
        artificial intelligence, LulAI enhances customer
        satisfaction by making shopping more intuitive,
        enjoyable, and tailored to individual preferences,
        thus creating a more engaging and interactive
        retail environment.
    </>
  );

  const paragraph2 = (
    <>
        The technology behind LulAI connects customers
        with the products they need in nearby stores,
        offering tailored suggestions based on their
        shopping history, preferences, and location. This
        not only helps customers find exactly what
        they are looking for but also introduces them
        to new products they might not have considered.
        For retailers, LulAI provides deep insights
        into consumer behavior and preferences, enabling
        them to optimize their store operations,
        inventory management, and marketing strategies,
        ultimately boosting sales and customer loyalty.
    </>
  );

  const paragraph3 = (
    <>
        By integrating LulAI, retailers can create a
        highly personalized shopping experience that
        resonates with customers on a deeper level. The
        platform is designed to seamlessly integrate with
        existing retail systems, making it easy for
        businesses to implement and start reaping the
        benefits quickly. The result is a smarter, more
        efficient retail environment where customer
        satisfaction is heightened, and operational
        efficiency is significantly improved.
    </>
  );

  return (
    <section id="lulai" className="bg-white text-gray-800">
      {/* Full-width Image */}
      <img
        src={projectImage}
        alt="LulAI"
        className="w-full h-64 md:h-96 object-cover rounded-lg mb-8"
      />

      {/* Content Section */}
      <div className="container mx-auto py-12 px-8">
        <h1 className="text-4xl font-bold text-center mb-8">
                    LulAI
        </h1>
        <div className="text-left">
          <p className="text-lg mb-6">{paragraph1}</p>
          <p className="text-lg mb-6">{paragraph2}</p>
          <p className="text-lg mb-6">
                        Key features of LulAI include:
          </p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>Personalized product recommendations based
                on customer preferences.</li>
            <li>Real-time assistance for customers during
                in-store shopping.</li>
            <li>Integration with local store inventories
                to connect customers with available products nearby.</li>
            <li>Valuable insights and analytics for retailers
                to optimize operations and enhance customer engagement.</li>
            <li>Seamless integration with existing retail systems.</li>
          </ul>
          <p className="text-lg mb-6">{paragraph3}</p>
        </div>
      </div>
    </section>
  );
};

export default LulAI;
