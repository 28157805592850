import React from 'react';
import {Link} from 'react-router-dom';
import logoImage from '../../assets/tiago_page/brito-consulting.png';

// Introduction Text
const introText =
    'At Brito Consulting, we are dedicated to empowering businesses through ' +
    'innovative technology solutions that drive growth and success. Whether ' +
    'you need software development, website design, or mobile' +
    ' app development, we are here to help you achieve your business goals.';

const howWeCanHelpText =
    'Discover how our expert team can help your business thrive in the ' +
    'digital age. From initial consultation to final deployment, ' +
    'we provide tailored solutions that meet your unique needs.';

const BritoConsulting = () => {
  return (
    <section id="brito-consulting" className="py-20 bg-white text-gray-800">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <img
            src={logoImage}
            alt="Brito Consulting Logo"
            className="w-56 h-56 rounded-full mx-auto"
          />
        </div>
        <h2 className="text-4xl font-bold text-center mb-8">
            Brito Consulting
        </h2>
        <p className="text-lg mb-6 max-w-7xl text-md md:text-lg
           font-open-sans lg:text-xl lg:leading-8 mx-auto px-8">
          {introText}
        </p>
        <p className="text-lg mb-6 max-w-7xl text-md md:text-lg
           font-open-sans lg:text-xl lg:leading-8 mx-auto px-8">
          {howWeCanHelpText}
        </p>
        <div className="flex justify-center">
          <Link
            to="/services"
            className="text-lg bg-blue-600 hover:bg-blue-700
            text-white py-3 px-8 rounded-full"
            onClick={() => window.scrollTo(0, 0)}
          >
              Learn More
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BritoConsulting;
