import React from 'react';
import visionImage from '../../assets/tiago_page/library_congress.jpg';

const VisionAndGoals = () => {
  const introduction = (
    <>
        As an entrepreneur, my journey is driven by a deep commitment to
        innovation, growth, and intellectual advancement. The path I am forging
        with Brito Consulting is just the beginning of a broader vision that
        spans both immediate business success and far-reaching contributions to
        various academic disciplines. My goals reflect a blend of
        entrepreneurial ambition and a passion for knowledge, as
        I strive to build a legacy that will resonate across industries
        and academic fields alike. I am driven by the desire to make a
        profound and lasting impact on the world, not only through the
        success of Brito Consulting but also through my contributions
        to the intellectual and cultural fabric of society. By
        pioneering innovative solutions in the tech industry and
        making significant contributions to a wide range of academic
        disciplines, I aim to inspire others and push the boundaries
        of what is possible. My vision is to create a legacy that
        advances both technology and human knowledge, driving progress
        and igniting change for generations to come.
    </>
  );

  const shortTermGoals = (
    <>
        My immediate focus is on making Brito Consulting a successful and
        influential player in the tech industry. I am committed to expanding
        the company’s reach and establishing it as a leader in tech solutions
        across North America. To achieve this, I plan to develop and implement
        innovative solutions that drive efficiency and growth for businesses,
        ensuring that Brito Consulting becomes synonymous with excellence and
        cutting-edge technology. Another key short-term goal is to scale the
        company to unicorn status, making it a billion-dollar enterprise known
        for its impact and success in the industry.
    </>
  );

  const longTermGoals = (
    <>
        Looking further ahead, my ambitions extend beyond entrepreneurship.
        I aspire to make significant contributions to a wide range of academic
        disciplines—philosophy, politics, law, economics, business, technology,
        engineering, physics, chemistry, biology, history, and classic
        literature—at the same level as the monumental figures who have shaped
        these fields, such as Aristotle, Thomas Jefferson, John Locke, Cicero,
        Adam Smith, John D. Rockefeller, Steve Jobs, Nikola Tesla, Leonardo da
        Vinci, Isaac Newton, Antoine Lavoisier, Charles Darwin,
        Yuval Noah Harari, and William Shakespeare. My goal is not
        only to be a successful entrepreneur but to also leave a
        lasting intellectual legacy. Ideally, I aim to create a unicorn
        in every industry I venture into, blending
        my passion for knowledge with my entrepreneurial drive.
    </>
  );

  return (
    <section id="vision-goals" className="py-20 bg-gray-50 text-gray-800">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center
        mb-12 mx-8">Vision and Goals</h2>

        {/* Image Section */}
        <div className="mb-12 px-8">
          <img
            src={visionImage}
            alt="Vision and Goals"
            className="w-full rounded-lg shadow-lg"
          />
        </div>

        {/* Text Section */}
        <div className="text-left">
          <div className="mb-8 px-8">
            <h3 className="text-2xl font-semibold
            mb-4">Personal and Professional Goals</h3>
            <p className="text-lg mb-6">{introduction}</p>
            <h4 className="text-xl font-bold mb-2">Short-Term Goals</h4>
            <p className="text-lg mb-6">{shortTermGoals}</p>
            <h4 className="text-xl font-bold mb-2">Long-Term Goals</h4>
            <p className="text-lg">{longTermGoals}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionAndGoals;
