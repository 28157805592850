import React from 'react';
import imageLisbon from '../../../assets/tiago_page/lisbon.jpg';

const PortugalMilestone = () => {
  const paragraph1 = (
    <>
        I was born in Lisbon to a Brazilian mother and
        a Portuguese father, and from a young age, I was
        imbued with the rich cultural heritages of both
        nations. Portugal, known for its age of exploration,
        has a long history of sailors and merchants who set
        out into the unknown, driven by a thirst for discovery
        and trade. Figures like Vasco da Gama and Ferdinand
        Magellan exemplify the adventurous and entrepreneurial
        spirit that is a part of Portugal’s legacy. This sense
        of adventure and curiosity seems to be woven into my very DNA.
    </>
  );

  const paragraph2 = (
    <>
        Growing up, I was always a rebel. It didn’t matter
        what the orders were; I simply didn’t like following
        them. I had an immense need for freedom and a boundless
        energy that I struggled to control. This need for
        freedom and boundless energy might have come from my
        Brazilian blood. Brazil, my mother’s homeland, has a
        history marked by its struggle for independence from
        Portuguese colonial rule, a struggle driven by a deep
        desire for self-determination and autonomy. Just as
        Brazil fought to forge its own path and break free
        from external constraints, I too have always sought to
        carve out my own path, unbound by the expectations
        and directives of others.
    </>
  );

  const paragraph3 = (
    <>
            The combination of these influences—the adventurous,
            entrepreneurial spirit of Portugal and the fierce
            independence of Brazil—has undoubtedly shaped me into
            the person I am today. After I started exercising
            regularly at the gym, I found that my mind became
            clearer, and I was able to focus more. The immense
            energy I once struggled to control turned into a
            powerful force, allowing me to work for long hours,
            sometimes 12 to 13 hours a day. With this background,
            becoming an entrepreneur was inevitable for me,
            driving me to explore new opportunities and make a
            lasting impact in the world of business.
    </>
  );

  return (
    <section className="py-10 md:py-20 bg-gray-50 text-gray-800 px-8">
      <div className="container mx-auto">
        <img src={imageLisbon} alt="Lisbon"
          className="w-full h-64 md:h-96 object-cover
           rounded-lg mb-8"/>
        <h2 className="text-3xl md:text-4xl
                font-bold mb-6">Birth and Early Life in Portugal</h2>
        <p className="text-lg md:text-xl
                leading-relaxed">{paragraph1}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph2}</p>
        <p className="text-lg md:text-xl
                leading-relaxed mt-4">{paragraph3}</p>
      </div>
    </section>
  );
};

export default PortugalMilestone;
