import React from 'react';
import {createRoot} from 'react-dom/client';
import './styles/index.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); // Create a root.

// Initial render: Render an element to the root.
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
);
