/**
 * Configuration File
 *
 * This file serves as the central location for defining and managing
 * global configuration values for the application. It is designed to store
 * constants such as company contact information, addresses, API endpoints,
 * and other settings that are used throughout the frontend application.
 */

export const COMPANY_ADDRESS =
    '20 Camden St, Suite 200, ON M5V 1V1, Toronto, Canada';
export const CONTACT_EMAIL =
    'contact@britoconsult.com';
export const CONTACT_NUMBER =
    '+1 (437) 830-3658';
