import React from 'react';
import projectImage
  from '../../../assets/tiago_page/projects_section/ord_excel.png';

const OffshoreRigging = () => {
  const paragraph1 = (
    <>
        ORD: Offshore Rigging Design is an advanced software
        solution that automates the complex process of rigging
        calculations and CAD drawing generation for the
        offshore engineering industry. This powerful tool
        is specifically designed to enhance efficiency and
        precision in rigging tasks, allowing engineers to
        focus on optimizing their designs rather than spending
        time on repetitive calculations and manual drafting.
        By integrating automated processes, ORD significantly
        reduces both the time and costs associated with rigging design.
    </>
  );

  const paragraph2 = (
    <>
        The platform streamlines the engineering workflow
        by automatically generating detailed CAD drawings
        that adhere to industry standards, such as those
        set by DNV (Det Norske Veritas). This ensures that
        every design is not only accurate but also compliant
        with global offshore engineering requirements. Engineers
        using ORD can easily perform rigorous calculations,
        adjust designs in real-time, and produce
        professional-grade CAD outputs, all within a single,
        unified platform.
    </>
  );

  const paragraph3 = (
    <>
        By adopting ORD: Offshore Rigging Design,
        companies in the offshore industry can experience
        a substantial improvement in project timelines
        and resource management. The software&apos;s ability
        to automate critical tasks leads to significant
        cost savings and ensures that projects are completed
        more efficiently. ORD is an indispensable tool for
        engineering firms aiming to maintain high standards
        of productivity, safety, and compliance in their
        rigging operations.
    </>
  );

  return (
    <section id="ord-offshore-rigging" className="bg-white text-gray-800">
      {/* Full-width Image */}
      <img
        src={projectImage}
        alt="ORD: Offshore Rigging Design"
        className="w-full h-64 md:h-96 object-cover rounded-lg mb-8"
      />

      {/* Content Section */}
      <div className="container mx-auto py-12 px-8">
        <h1 className="text-4xl font-bold text-center mb-8">
                    ORD: Offshore Rigging Design
        </h1>
        <div className="text-left">
          <p className="text-lg mb-6">{paragraph1}</p>
          <p className="text-lg mb-6">{paragraph2}</p>
          <p className="text-lg mb-6">
                        Key features of ORD: Offshore Rigging Design include:
          </p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>Automated rigging calculations for offshore
                engineering tasks.</li>
            <li>Automatic generation of CAD drawings to industry standards.</li>
            <li>Improved accuracy and efficiency in rigging design.</li>
            <li>Streamlined workflow integration for engineering teams.</li>
            <li>Compliance with global offshore engineering standards.</li>
          </ul>
          <p className="text-lg mb-6">{paragraph3}</p>
        </div>
      </div>
    </section>
  );
};

export default OffshoreRigging;
