import inquiryIcon from
  '../../assets/home_page/process_icons/magnifying-glass-solid.png';
import meetingIcon from
  '../../assets/home_page/process_icons/handshake-solid.png';
import designIcon from
  '../../assets/home_page/process_icons/pencil-solid.png';
import developmentIcon from
  '../../assets/home_page/process_icons/code-solid.png';
import deliveryIcon from
  '../../assets/home_page/process_icons/rocket-solid.png';

const Process = () => {
  const steps = [
    {
      icon: inquiryIcon,
      title: 'Inquiry',
      description: 'Understanding your needs and goals.',
      iconClass: 'w-5 h-5',
    },
    {
      icon: meetingIcon,
      title: 'Meeting',
      description: 'Discussing project details and requirements.',
      iconClass: 'w-6 h-5',
    },
    {
      icon: designIcon,
      title: 'Design',
      description: 'Creating initial designs and getting feedback.',
      iconClass: 'w-5 h-5',
    },
    {
      icon: developmentIcon,
      title: 'Development',
      description: 'Building the solution with quality and speed.',
      iconClass: 'w-7 h-5',
    },
    {
      icon: deliveryIcon,
      title: 'Delivery',
      description: 'Delivering the final product and support.',
      iconClass: 'w-5 h-5',
    },
  ];

  return (
    <section className="bg-[#FFFFFF] py-12 sm:py-16 lg:py-20 xl:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="text-sm font-bold uppercase tracking-widest
          text-[#004080]">
            Our Process
          </p>
          <h2 className="mt-6 text-3xl font-bold tracking-tight sm:text-4xl
          lg:text-5xl text-[#004080]">
            Simple Steps to Your Success
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-lg font-normal
          text-[#333333] lg:text-xl lg:leading-8">
            We guide you through our process to
            achieve your goals efficiently
          </p>
        </div>
        <ul className="mx-auto mt-12 grid max-w-md grid-cols-1 gap-6
        lg:gap-10 sm:mt-16 lg:mt-20 lg:max-w-5xl lg:grid-cols-5">
          {steps.map((step, index) => (
            <li key={index} className="flex-start group relative flex
            lg:flex-col lg:items-center">
              {index !== steps.length - 1 && (
                <span
                  className="absolute left-[20px] top-12 h-[calc(100%_-_32px)]
                  w-px bg-[#004080] lg:-right-16 lg:left-auto lg:top-[18px]
                  lg:h-px lg:w-[calc(100%_-_72px)]"
                  aria-hidden="true"
                ></span>
              )}
              <div
                className="inline-flex h-10 w-10 shrink-0 items-center
                justify-center rounded-xl border bg-[#FFFFFF] drop-shadow-sm
                border-neutral-200/50 group-hover:bg-neutral-200
                transition-colors duration-500"
              >
                <img src={step.icon} alt={step.title}
                  className={`${step.iconClass}`} />
              </div>
              <div className="ml-6 lg:ml-0 lg:mt-6">
                <h3 className="text-xl font-bold text-[#333333] before:mb-2
                before:block before:font-mono before:text-sm
                before:text-gray-500 lg:text-center">
                  {step.title}
                </h3>
                <h4 className="mt-2 text-base lg:text-sm lg:text-center
                text-[#333333]">{step.description}</h4>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Process;
