import React from 'react';
import {Link} from 'react-router-dom';

const CallToAction = () => {
  const paragraph1 = (
    <>
        I’m always open to potential collaborations, investments, or inquiries.
        Feel free to reach out, and let’s explore how we can work together to
        achieve great things.
    </>
  );

  return (
    <section id="call-to-action" className="py-20 bg-[#004080] text-white">
      <div className="container mx-auto text-center px-8">
        <h2 className="text-3xl font-bold mb-8">Let’s Connect</h2>
        <p className="text-lg mb-8">{paragraph1}</p>
        <Link
          to="/contact"
          className="py-3 px-8 bg-white text-[#004080] text-lg
          font-semibold rounded-full hover:bg-gray-100 transition"
          onClick={() => window.scrollTo(0, 0)}
        >
                    Get in Touch
        </Link>
      </div>
    </section>
  );
};

export default CallToAction;
